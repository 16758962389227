import React, { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { Calendar, DateRangePicker } from "react-date-range";
import { AiFillCalendar, AiOutlineWhatsApp } from "react-icons/ai";
import CustomSkeleton from "../../../components/Skeleton";
import useIntelligenceDateRange from "../../../utils/dateRange";
import { DownloadCancellationReport } from "../DownloadExcel/CancellationReport";
import NewRateChangeReportTable from "../../newTable/newRateRangeRepotrTable";
import NewOtaOptions from "../../../utils/newOTAOptions";
import { useIsIpad } from "../../../utils/isIpad";
import CheckBox from "../../../components/checkbox/CheckBox";
import Dropdown from "../../../components/dropdown";
import { MultiSelectNestedDropdown } from "../../../components/dropdown/subComponent/multiSelectNestedDropdown";
import { DownloadRevenueDeckReportExcel } from "../DownloadExcel/RevenueDeckReportExcel";


const RevenueDeckReport = ({
    data,
    isLoading,
    setIsWhatsApp,sendToWhatsappAdmin,
    handleDownload,
    dateRange,
    setDateRange
}) => {
    // const [showDateRange, setShowDateRange] = useState(false);
    const dateLimit = useIntelligenceDateRange();
    const [showDateRange, setShowDateRange] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(0);
    const fromDate = format(new Date(dateLimit?.fromDate), "MM/dd/yyyy");
    const endDate = format(new Date(dateLimit?.toDate), "MM/dd/yyyy");
    const ref1 = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref1?.current && !ref1?.current.contains(event.target)) {
                setShowDateRange(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1]);
    const handleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    const options = NewOtaOptions();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);
    const isIpad = useIsIpad();
    console.log(data,'aman')


    return (
        <div className="h-[80vh]">
            <div className="h-[10%] my-4 flex items-center text-white dark:text-black justify-between">
                <h1 className="text-[24px] font-bold"> Hotel Revenue Deck Report</h1>
                <div className="flex flex-row justify-between items-center">
                    <div className=" flex items-center gap-2 justify-center pr-2">
                        <div className="w-fit font-semibold text-[17px]">Select date</div>
                        {
                            <div
                                className="relative mb-2 w-fit justify-start text-xs cursor-pointer select-none flex gap-4 items-center mx-1 px-2 py-3 text-white dark:text-black rounded-md border-solid border border-[#A0A4B0] z-20"
                                ref={ref1}
                            >
                                <div
                                    className="flex  text-white dark:text-black  gap-2 items-center"
                                    onClick={() => setShowDateRange(!showDateRange)}
                                >
                                    {" "}
                                    <AiFillCalendar size={14} />{" "}
                                    {format(new Date(dateRange), "dd MMM yyy") || " "}{" "}
                                </div>
                                {showDateRange && (
                                <div className="absolute top-[50px] -left-[250px] text-black">
                                    <Calendar
                                    date={dateRange}
                                    onChange={(e) => {
                                        setDateRange(e);
                                        setShowDateRange(!showDateRange);
                                    }}
                                    minDate={new Date(fromDate)}
                                    maxDate={new Date(endDate)}
                                    />
                                </div>
                                )}
                            </div>
                        }
                    </div>
                    <h1
                        onClick={() => {handleDownload()
                        }}
                        className="flex m-auto   justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[30px] mr-2 px-2 py-1 text-sm text-white dark:text-mainDark font-medium text-[17px]"
                    >
                        Download
                    </h1>{" "}
                    <div ref={dropdownRef} className="relative">
                        {/* Main Button */}
                        <div
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className="flex m-auto w-[180px] gap-2 justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#3ec329] h-[30px] mr-2 px-2 py-1 text-xs text-white  font-medium text-[17px]"
                        >
                            <AiOutlineWhatsApp size={18} /> Send via WhatsApp
                        </div>


                        {isDropdownOpen && (
                            <div className="absolute mt-2 w-[180px] text-white text-xs px-4 rounded-lg  shadow-lg dark:bg-[#1D3893] bg-[#3ec329]">
                                <div
                                    onClick={() => {
                                        setIsDropdownOpen(false);
                                        setIsWhatsApp(); // Handle Send to Self
                                    }}
                                    className="flex gap-2 justify-start items-center p-2 cursor-pointer"
                                >
                                    <AiOutlineWhatsApp size={18} /> Send to self
                                </div>
                                <div
                                    onClick={() => {
                                        setIsDropdownOpen(false);
                                        sendToWhatsappAdmin();
                                        // Handle Send to Personal Number
                                    }}
                                    className="flex gap-2 justify-start items-center p-2  cursor-pointer"
                                >
                                    <AiOutlineWhatsApp size={18} /> Send to secondary
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>{" "}
            {/* -------------------------------------------------------------------------- */
            /*                                 Pickup Data                                */
            /* -------------------------------------------------------------------------- */}
            <div className="flex flex-col gap-2 mt-2 overflow-y-scroll ">
                {!isLoading ? (
                    <div className="flex flex-col ">
                        <div className="flex flex-row justify-between w-[100%]">
                            <h1 className="text-white dark:text-black text-[24px] font-[700]">
                                {" "}
                                Pickup Trend
                            </h1>
                        </div>

                        {/* Today's comparison */}
                        <NewRateChangeReportTable
                            data={data?.data?.pickupTrend?.thisMonth}
                            handleExpand={handleExpand}
                            label={"This Month"}
                            isExpanded={expandedIndex === 0}
                            onExpand={() => handleExpand(0)}
                        />
                        {/* Last Month Comparison */}
                        <NewRateChangeReportTable
                            data={data?.data?.pickupTrend?.lastMonth}
                            handleExpand={handleExpand}
                            label={"Last Month"}
                            isExpanded={expandedIndex === 1}
                            onExpand={() => handleExpand(1)}
                        />
                        {/* Next Month Comparison */}
                        <NewRateChangeReportTable
                            data={data?.data?.pickupTrend?.nextMonth}
                            handleExpand={handleExpand}
                            label={"Next Month"}
                            isExpanded={expandedIndex === 2}
                            onExpand={() => handleExpand(2)}
                        />
                        {/* This Month Comparison */}
                        <NewRateChangeReportTable
                            data={data?.data?.pickupTrend?.sameMonthLastYear}
                            handleExpand={handleExpand}
                            label={"Same Month Last Year"}
                            isExpanded={expandedIndex === 3}
                            onExpand={() => handleExpand(3)}
                        />
                    </div>
                ) : (
                    <div className="w-[100%] mt-[50px]">
                        <CustomSkeleton height={400} />
                    </div>
                )}

            </div>


            {/* -------------------------------------------------------------------------- */
            /*                                 Pace Data                                */
            /* -------------------------------------------------------------------------- */}
            <div className="flex flex-col gap-2 mt-2 overflow-y-scroll ">
                {!isLoading ? (
                    <div className="flex flex-col ">
                        <div className="flex flex-row justify-between w-[100%]">
                            <h1 className="text-white dark:text-black text-[24px] font-[700]">
                                {" "}
                                Pace Trend
                            </h1>
                        </div>

                        {/* Weekly Arrival */}
                        <NewRateChangeReportTable
                            data={data?.data?.paceTrend?.weeklyArrival}
                            handleExpand={handleExpand}
                            label={"Weekly Arrival"}
                            isExpanded={expandedIndex === 4}
                            onExpand={() => handleExpand(4)}
                        />
                        {/* Weekly Reservation */}
                        <NewRateChangeReportTable
                            data={data?.data?.paceTrend?.weeklyReservation}
                            handleExpand={handleExpand}
                            label={"Weekly Reservation"}
                            isExpanded={expandedIndex === 5}
                            onExpand={() => handleExpand(5)}
                        />
                        {/* Weekly Room Night */}
                        <NewRateChangeReportTable
                            data={data?.data?.paceTrend?.weeklyRoomNights}
                            handleExpand={handleExpand}
                            label={"Weekly Room Nights"}
                            isExpanded={expandedIndex === 6}
                            onExpand={() => handleExpand(6)}
                        />
                    </div>
                ) : (
                    <div className="w-[100%] mt-[50px]">
                        <CustomSkeleton height={400} />
                    </div>
                )}

            </div>


            {/* -------------------------------------------------------------------------- */
            /*                                 OTA Productivity                                */
            /* -------------------------------------------------------------------------- */}
            <div className="flex flex-col gap-2 mt-2 overflow-y-scroll ">
                {!isLoading ? (
                    <div className="flex flex-col ">
                        <div className="flex flex-row justify-between w-[100%]">
                            <h1 className="text-white dark:text-black text-[24px] font-[700]">
                                {" "}
                                OTA Productivity
                            </h1>
                        </div>

                        {/* This Year */}

                        <div className="flex flex-row justify-between w-[100%]">
                            <h1 className="text-white dark:text-black text-[18px] font-[700]">
                                {" "}
                                This Year
                            </h1>
                        </div>
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.thisYear?.revenueBreakdown}
                            handleExpand={handleExpand}
                            label={"This Year OTA'wise Revenue Breakdown"}
                            isExpanded={expandedIndex === 7}
                            onExpand={() => handleExpand(7)}
                        />
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.thisYear?.rnsBreakdown}
                            handleExpand={handleExpand}
                            label={"This Year OTA'wise Room Nights Breakdown"}
                            isExpanded={expandedIndex === 8}
                            onExpand={() => handleExpand(8)}
                        />
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.thisYear?.adrBreakdown}
                            handleExpand={handleExpand}
                            label={"This Year OTA'wise ADR Breakdown"}
                            isExpanded={expandedIndex === 9}
                            onExpand={() => handleExpand(9)}
                        />
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.thisYear?.losBreakdown}
                            handleExpand={handleExpand}
                            label={"This Year OTA'wise Lead Time Breakdown"}
                            isExpanded={expandedIndex === 10}
                            onExpand={() => handleExpand(10)}
                        />
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.thisYear?.leadTimeBreakdown}
                            handleExpand={handleExpand}
                            label={"This Year OTA'wise Length of Stay Breakdown"}
                            isExpanded={expandedIndex === 11}
                            onExpand={() => handleExpand(11)}
                        />


                        {/* Last Year */}
                        <div className="flex flex-row justify-between w-[100%]">
                            <h1 className="text-white dark:text-black text-[18px] font-[700]">
                                {" "}
                                Last Year
                            </h1>
                        </div>
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.lastYear?.revenueBreakdown}
                            handleExpand={handleExpand}
                            label={"Last Year OTA'wise Revenue Breakdown"}
                            isExpanded={expandedIndex === 12}
                            onExpand={() => handleExpand(12)}
                        />
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.lastYear?.rnsBreakdown}
                            handleExpand={handleExpand}
                            label={"Last Year OTA'wise Room Nights Breakdown"}
                            isExpanded={expandedIndex === 13}
                            onExpand={() => handleExpand(13)}
                        />
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.lastYear?.adrBreakdown}
                            handleExpand={handleExpand}
                            label={"Last Year OTA'wise ADR Breakdown"}
                            isExpanded={expandedIndex === 14}
                            onExpand={() => handleExpand(14)}
                        />
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.lastYear?.losBreakdown}
                            handleExpand={handleExpand}
                            label={"Last Year OTA'wise Lead Time Breakdown"}
                            isExpanded={expandedIndex === 15}
                            onExpand={() => handleExpand(15)}
                        />
                        <NewRateChangeReportTable
                            data={data?.data?.otaProductivity?.lastYear?.leadTimeBreakdown}
                            handleExpand={handleExpand}
                            label={"Last Year OTA'wise Length of Stay Breakdown"}
                            isExpanded={expandedIndex === 16}
                            onExpand={() => handleExpand(16)}
                        />
                    </div>
                ) : (
                    <div className="w-[100%] mt-[50px]">
                        <CustomSkeleton height={400} />
                    </div>
                )}

            </div>


            {/* -------------------------------------------------------------------------- */
            /*                                 reputation                                */
            /* -------------------------------------------------------------------------- */}
            <div className="flex flex-col gap-2 mt-2 overflow-y-scroll ">
                {!isLoading ? (
                    <div className="flex flex-col ">
                        <div className="flex flex-row justify-between w-[100%]">
                            <h1 className="text-white dark:text-black text-[24px] font-[700]">
                                {" "}
                                Visibility & Reputation
                            </h1>
                        </div>

                        {/* Weekly Arrival */}
                        <NewRateChangeReportTable
                            data={data?.data?.reputation?.thisMonth}
                            handleExpand={handleExpand}
                            label={"This Week"}
                            isExpanded={expandedIndex === 17}
                            onExpand={() => handleExpand(17)}
                        />
                        {/* Weekly Reservation */}
                        <NewRateChangeReportTable
                            data={data?.data?.reputation?.lastMonth}
                            handleExpand={handleExpand}
                            label={"Last Week"}
                            isExpanded={expandedIndex === 18}
                            onExpand={() => handleExpand(18)}
                        />
                    </div>
                ) : (
                    <div className="w-[100%] mt-[50px]">
                        <CustomSkeleton height={400} />
                    </div>
                )}

            </div>
        </div>
    );
};

export default RevenueDeckReport;
