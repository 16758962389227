import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PaceReport from "./Tables/PaceReport";
import MonthEndTable from "./Tables/MonthEndReport";
import RevenueReportTable from "./Tables/RevenueReport";
import { useNavigate } from "react-router-dom";
import BackButton from "../../assets/icons/VectorSetting.svg";
import SegmentReport from "./Tables/SegmentReport";
import SourceReport from "./Tables/SourceReport";
import QuarterlyReport from "./Tables/QuarterlyReport";
import ParityReport from "./Tables/ParityReport";
import { CurrentProperty, UserId } from "../../utils/impVars";
import {
  useArcrEdRateDataQuery,
  useArcrEdRateFluctuationDataQuery,
  useArcrEdReputationRankReviewDataQuery,
  useArcrIdMetricsDataQuery,
  useArcrIdPaceDataQuery,
  useDynamicRateChangeReportQuery,
  useGetActualRateChangeReportQuery,
  useGetCompsetReportQuery,
  useGetMonthEndReportQuery,
  useGetPaceReportQuery,
  useGetParityReportQuery,
  useGetPickUpReportQuery,
  useGetQuarterlyReportQuery,
  useGetRateChangeReportQuery,
  useGetRatesReportQuery,
  useGetRevenueDeckReportQuery,
  useGetRevenueReportQuery,
  useGetSegmentReportQuery,
  useGetSourceReportQuery,
  useGetYearEndReportQuery,
  useHotelProductivityReportQuery,
} from "../../redux/slices/reports";
import { DownloadMonthEndReport } from "./DownloadExcel/MonthEndReport";
import { DownloadPaceReport } from "./DownloadExcel/PaceReport";
import { DownloadRevenueReport } from "./DownloadExcel/RevenueReport";
import { DownloadSegmentReport } from "./DownloadExcel/SegmentReport";
import { DownloadSourceReport } from "./DownloadExcel/SourceReport";
import { DownloadQuaterlyReport } from "./DownloadExcel/QuaterlyReport";
import { DownloadParityReport } from "./DownloadExcel/ParityReport";
import YearEndReport from "./Tables/YearEndReport";
import { toast } from "react-toastify";
import RatesReport from "./Tables/RatesReport";
import PickUpReport from "./Tables/PickUpReport";
import useIntelligenceDateRange from "../../utils/dateRange";
import useRatePulseDateRange from "../../utils/ratepulseDateRange";
import { useGetInventoryQuery } from "../../redux/slices/ratesInventory";
import { format, isDate } from "date-fns";
import { YearEndReportdownload } from "./DownloadExcel/YearEndReport";
import RateChangeReport from "./Tables/RateChangeReport";
import OccupencyReport from "./Tables/OccupencyReport";
import CancellationReport from "./Tables/CancellationReport";
import { DownloadCancellationReport } from "./DownloadExcel/CancellationReport";
import DynamicRateChangeReport from "./Tables/DynamicRateChangeReport";
import { DownloadDynamicRateChangeReport } from "./DownloadExcel/DynamicRateChangeReport";
import CompsetReport from "./Tables/CompsetReport";
import { DownloadCompsetReport } from "./DownloadExcel/CompsetReport";
import { DownloadHotelProductivityReport } from "./DownloadExcel/HotelProductivityReport";
import HotelProductivityReportTable from "./Tables/HotelProductivityReport";
import { DownloadRevenueDeckReportExcel } from "./DownloadExcel/RevenueDeckReportExcel";
import RevenueDeckReport from "./Tables/RevenueDeckReport";

function formatDate(date) {
  // Get year, month, and day
  console.log(isDate(date), date, "date.getfullYear()")
  let year = new Date(date).getFullYear();
  let month = new Date(date).getMonth() + 1; // Months are zero-based
  let day = new Date(date).getDate();

  // Pad month and day with leading zeros if necessary
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  // Construct the formatted date string
  return `${year}-${month}-${day}`;
}

const ReportId = () => {
  const { reportId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // Helper function to get query parameters
  const getQueryParams = (param) => {
    return new URLSearchParams(location.search).get(param);
  };
  const [skipQuarterly, setSkipQuarterly] = useState(true);
  const [skipRevenue, setSkipRevenue] = useState(true);
  const [skipMonth, setSkipMonth] = useState(true);
  const [skipCompsetReport, setSkipCompsetReport] = useState(true);
  const [skipHotelProductivityReport, setSkipHotelProductivityReport] = useState(true);
  const [skipYear, setSkipYear] = useState(true);
  const [skipPace, setSkipPace] = useState(true);
  const [skipSource, setSkipSource] = useState(true);
  const [skipParity, setSkipParity] = useState(true);
  const [skipRate, setSkipRate] = useState(true);
  const [skipPickup, setSkipPickup] = useState(true);
  const [skipInventory, setSkipInventory] = useState(true);
  const [skipCancellation, setSkipCancellation] = useState(true);
  const [skipActualRateChange, setSkipActualRateChange] = useState(true);
  const [skipArcrIdMetricsChange, setSkipArcrIdMetricsChange] = useState(true);
  const [skipArcrIdPaceChange, setSkipArcrIdPaceChange] = useState(true);
  const [skipArcrEdRateChange, setSkipArcrEdRateChange] = useState(true);
  const [skipArcrEdRateFluctuationChange, setSkipArcrEdRateFluctuationChange] =
    useState(true);
  const [
    skipArcrEdReputationRankReviewChange,
    setSkipArcrEdReputationRankReviewChange,
  ] = useState(true);
  const [skipDynamicRateChange, setSkipDynamicRateChange] = useState(true);

  useEffect(() => {
    setSkipQuarterly(reportId === "quarep" ? false : true);
    setSkipPace(reportId === "parep" ? false : true);
    setSkipMonth(reportId === "moenrep" ? false : true);
    setSkipYear(reportId === "yeenrep" ? false : true);
    setSkipRevenue(reportId === "revrep" ? false : true);
    setSkipSource(reportId === "sourep" ? false : true);
    setSkipPickup(reportId === "pickrep" ? false : true);
    setSkipParity(reportId === "parrep" ? false : true);
    setSkipRate(reportId === "ratrep" ? false : true);
    setSkipInventory(reportId === "occrep" ? false : true);
    setSkipCancellation(reportId === "canrep" ? false : true);
    setSkipActualRateChange(reportId === "rachrepra" ? false : true);
    setSkipArcrIdMetricsChange(reportId === "rachrepra" ? false : true);
    setSkipArcrIdPaceChange(reportId === "rachrepra" ? false : true);
    setSkipArcrEdRateChange(reportId === "rachrepra" ? false : true);
    setSkipArcrEdRateFluctuationChange(reportId === "rachrepra" ? false : true);
    setSkipArcrEdReputationRankReviewChange(
      reportId === "rachrepra" ? false : true
    );
    setSkipDynamicRateChange(reportId === "dyrachrep" ? false : true);
    setSkipCompsetReport(reportId === "comprarep" ? false : true);
    setSkipHotelProductivityReport(reportId === "hoprorep" ? false : true);
  }, []);

  const [segmentDate, setSegmentDate] = useState(new Date());
  const intelligenceDateRange = useIntelligenceDateRange();
  const ratePulseDateRange = useRatePulseDateRange();

  const [parityWhatsApp, setParityWhatsApp] = useState(false);
  const [quaterlyWhatsApp, setQuaterlyWhatsApp] = useState(false);
  const [revenueWhatsApp, setRevenueWhatsApp] = useState(false);
  const [monthEndWhatsApp, setMonthEndWhatsApp] = useState(false);
  const [compsetReportWhatsApp, setCompsetReportWhatsApp] = useState(false);
  const [hotelProductivityWhatsApp, setHotelProductivityWhatsApp] = useState(false);

  const [dynamicRateChangeWhatsApp, setDynamicRateChangeWhatsApp] =
    useState(false);
  const [yearEndWhatsApp, setYearEndWhatsApp] = useState(false);
  const [sourceWhatsApp, setSourceWhatsApp] = useState(false);
  const [paceWhatsApp, setPaceWhatsApp] = useState(false);
  const [paceWhatsAppAdmin, setPaceWhatsAppAdmin] = useState(false);
  const [pickUpWhatsApp, setPickUpWhatsApp] = useState(false);
  const [rateChangeWhatsApp, setRateChangeWhatsApp] = useState(false);

  const [segmentWhatsApp, setSegmentWhatsApp] = useState(false);
  const [cancellationWhatsapp, setCancellationWhatsApp] = useState(false)
  const [revenueDeckWhatsApp, setRevenueDeckWhatsApp] = useState(false)
  const [ratesWhatsApp, setRatesWhatsApp] = useState(false);
  const [ratesWhatsAppAdmin, setRatesWhatsAppAdmin] = useState(false);
  const [inventoryWhatsApp, setInventoryWhatsApp] = useState(false);
  const [inventoryWhatsAppAdmin, setInventoryWhatsAppAdmin] = useState(false);

  const [QuaterlyWhatsAppAdmin, setQuaterlyWhatsAppAdmin] = useState(false);
  const [revenueWhatsAppAdmin, setRevenueWhatsAppAdmin] = useState(false);
  const [rateChangeWhatsAppAdmin, setRateChangeWhatsAppAdmin] = useState(false);
  const [monthEndWhatsAppAdmin, setMonthEndWhatsAppAdmin] = useState(false);
  const [dynamicRateChangeWhatsAppAdmin, setDynamicRateChangeWhatsAppAdmin] =
    useState(false);
  const [sourceWhatsAppAdmin, setSourceWhatsAppAdmin] = useState(false);
  const [parityWhatsAppAdmin, setParityWhatsAppAdmin] = useState(false);
  const [yearEndWhatsAppAdmin, setYearEndWhatsAppAdmin] = useState(false);
  const [hotelProductivityReportAdmin, setHotelProductivityReportAdmin] = useState(false);
  const [compsetReportWhatsAppAdmin, setCompsetReportWhatsAppAdmin] = useState(false);
  const [cancellationWhatsAppAdmin, setCancellationWhatsAppAdmin] = useState(false)
  const newDate = new Date();
  const [paramsStartDate, setParamsStartDate] = useState(getQueryParams('startDate') || null);
  const [paramsEndDate, setParamsEndDate] = useState(getQueryParams('endDate') || null);
  const [pickUpDate,setPickupDate] = useState(new Date)

  console.log(paramsStartDate, "params start date")
  const [monthEndDateRange, setMonthEndDateRange] = useState([
    paramsStartDate ? formatDate(
      new Date(paramsStartDate)
    ) : formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    paramsEndDate ? formatDate(
      new Date(paramsEndDate)
    ) : formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    ),
  ]);



  function getDate30DaysAfter() {
    const today = new Date();
    const thirtyDaysLater = new Date(today);
    thirtyDaysLater.setDate(today.getDate() + 29);
    return thirtyDaysLater;
  }

  const [compsetReportDateRange, setCompsetReportDateRange] = useState([
    {
      startDate: paramsStartDate ? formatDate(
        new Date(paramsStartDate)
      ) : new Date(),
      endDate: paramsEndDate ? formatDate(
        new Date(paramsEndDate)
      ) : getDate30DaysAfter(), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);

  const [dynamicRateChangeDateRange, setDynamicRateChangeDateRange] = useState([
    {
      startDate: paramsStartDate ? formatDate(
        new Date(paramsStartDate)
      ) : new Date(),
      endDate: paramsEndDate ? formatDate(
        new Date(paramsEndDate)
      ) : getDate30DaysAfter(), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);

  const [bifercationType, setBifercationType] = useState("none");
  const [parityDateRange, setParityDateRange] = useState([
    {
      startDate: paramsStartDate ? formatDate(
        new Date(paramsStartDate)
      ) : newDate,
      endDate: new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);

  const [metricSourceSelector, setMetricSourceSelector] = useState("");
  const [metricDateRangeSelector, setMetricDateRangeSelector] = useState("day");
  const [paceSourceSelector, setPaceSourceSelector] = useState("");
  const [paceDateRangeSelector, setPaceDateRangeSelector] = useState("day");
  const [fluctuationDateRangeType, setFluctuationDateRangeType] = useState({
    value: "last7",
    label: "Last 7 Days",
  });

  useEffect(() => {
    if (ratePulseDateRange?.fromDate) {
      const startDate = new Date(ratePulseDateRange.toDate);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 30);
      setParityDateRange([
        {
          startDate: startDate,
          endDate: endDate,
          id: "range 1",
        },
      ]);
    }
  }, [ratePulseDateRange]);
  const [selectedInventryDateRange, setSelectedInventryDateRange] = useState(
    new Date()
  );

  const [selectedDate, setSelectedDate] = useState(paramsStartDate ? formatDate(
    new Date(paramsStartDate)
  ) : new Date());
  const [quarterlyDateRange, setQuaterlyDateRange] = useState([
    paramsStartDate ? formatDate(
      new Date(paramsStartDate)
    ) : formatDate(
      new Date(newDate.getFullYear(), newDate.getMonth() - 3, 1),
      "dd-MM-yyyy"
    ),
    paramsEndDate ? formatDate(
      new Date(paramsEndDate)
    ) : formatDate(
      new Date(newDate.getFullYear(), newDate.getMonth(), 0),
      "dd-MM-yyyy"
    ),
  ]);
  const [revenueDateRange, setRevenueDateRange] = useState([
    {
      startDate: paramsStartDate ? formatDate(
        new Date(paramsStartDate)
      ) : new Date(),
      endDate: paramsEndDate ? formatDate(
        new Date(paramsEndDate)
      ) : new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        newDate.getDate()
      ),
      id: "range 1",
    },
  ]);
  const [paceDateRange, setPaceDateRange] = useState([
    {
      startDate: paramsStartDate ? formatDate(
        new Date(paramsStartDate)
      ) : formatDate(new Date()),
      endDate: paramsEndDate ? formatDate(
        new Date(paramsEndDate)
      ) : formatDate(new Date(
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        newDate.getDate()
      )),
      id: "range 1",
    },
  ]);
  const [sourceDateRange, setSourceDateRange] = useState([
    {
      startDate: paramsStartDate ? format(new Date(paramsStartDate), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
      endDate: paramsEndDate ? format(new Date(paramsEndDate), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
      id: "range 1",
    },
  ]);
  const [yearDateRange, setYearDateRange] = useState([
    paramsStartDate ? formatDate(
      new Date(paramsStartDate)
    ) : formatDate(new Date(newDate.getFullYear() - 1, 0, 1), "dd-MM-yyyy"),
    paramsEndDate ? formatDate(
      new Date(paramsEndDate)
    ) : formatDate(new Date(newDate.getFullYear() - 1, 11, 31), "dd-MM-yyyy"),
  ]);
  const [hotelProductivityDateRange, setHotelProductivityDateRange] = useState([
    paramsStartDate ? formatDate(
      new Date(paramsStartDate)
    ) : formatDate(new Date(newDate.getFullYear(), 0, 1), "dd-MM-yyyy"),
    paramsEndDate ? formatDate(
      new Date(paramsEndDate)
    ) : formatDate(new Date(newDate.getFullYear(), 11, 31), "dd-MM-yyyy"),
  ]);
  const [pickUpDateRange, setPickUpDateRange] = useState([
    {
      startDate: paramsStartDate ? formatDate(
        new Date(paramsStartDate)
      ) : newDate,
      endDate: paramsEndDate ? formatDate(
        new Date(paramsEndDate)
      ) : new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);
  const [cancellationDateRange, setCancellationDateRange] = useState([
    {
      startDate: paramsStartDate ? formatDate(
        new Date(paramsStartDate)
      ) : newDate,
      endDate: paramsEndDate ? formatDate(
        new Date(paramsEndDate)
      ) : new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);

  const [selectedRevenueDeckDate, setSelectedRevenueDeckDate] = useState(new Date());
  const [pickUpWhatsAppAdmin, setPickUpWhatsAppAdmin] = useState(false);

  const {
    data: pickUpData,
    isFetching: pickupFetching,
    isLoading: pickupLoading,
  } = useGetPickUpReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(pickUpDate),
      // endDate: formatDate(pickUpDateRange[0].endDate),
      isPrimary: pickUpWhatsApp,
      isSecondary: setPickUpWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipPickup }
  );
  const {
    data: revenueData,
    isLoading: revenueLoading,
    isFetching: revenueIsFetching,
    isSuccess: revenueSuccess,
  } = useGetRevenueReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(revenueDateRange[0]?.startDate),
      endDate: formatDate(revenueDateRange[0]?.endDate),
      isPrimary: revenueWhatsApp,
      isSecondary: revenueWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipRevenue }
  );
  useEffect(() => {
    if (revenueSuccess && (revenueWhatsApp || revenueWhatsAppAdmin)) {
      setRevenueWhatsApp(false);
      setRevenueWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [revenueLoading, revenueIsFetching, revenueSuccess]);
  const {
    data: paceData,
    refetch: paceDataRefetch,
    isLoading: paceLoading,
    isFetching: paceFetching,
    isSuccess: paceSuccess,
  } = useGetPaceReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: paceDateRange[0]?.startDate,
      endDate: paceDateRange[0]?.endDate,
      isPrimary: paceWhatsApp,
      isSecondary: paceWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipPace }
  );
  useEffect(() => {
    if (paceSuccess && (paceWhatsApp || paceWhatsAppAdmin)) {
      setPaceWhatsApp(false);
      setPaceWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [paceLoading, paceFetching, paceSuccess]);
  const {
    data: monthEndData,
    isLoading: monthLoading,
    isFetching: monthFetching,
    isSuccess: monthSuccess,
  } = useGetMonthEndReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: monthEndDateRange[0],
      endDate: monthEndDateRange[1],
      isPrimary: monthEndWhatsApp,
      isSecondary: monthEndWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipMonth }
  );
  const {
    data: compsetReportData,
    isLoading: compsetReportLoading,
    isFetching: compsetReportFetching,
    isSuccess: compsetReportSuccess,
  } = useGetCompsetReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(compsetReportDateRange[0].startDate),
      endDate: formatDate(compsetReportDateRange[0].endDate),
      whatsAppNotify: compsetReportWhatsApp,
      admin: compsetReportWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipCompsetReport }
  );
  const {
    data: hotelProductivityReportData,
    isLoading: hotelProductivityReportLoading,
    isFetching: hotelProductivityReportFetching,
    isSuccess: hotelProductivityReportSuccess,
  } = useHotelProductivityReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: hotelProductivityDateRange[0],
      endDate: hotelProductivityDateRange[1],
      isPrimary: hotelProductivityWhatsApp,
      isSecondary: hotelProductivityReportAdmin
    },
    { refetchOnMountOrArgChange: true, skip: skipHotelProductivityReport }
  );
  const {
    data: dynamicRateChangeData,
    isLoading: dynamicRateChangeLoading,
    isFetching: dynamicRateChangeFetching,
    isSuccess: dynamicRateChangeSuccess,
  } = useDynamicRateChangeReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(dynamicRateChangeDateRange[0].startDate),
      endDate: formatDate(dynamicRateChangeDateRange[0].endDate),
      isPrimary: dynamicRateChangeWhatsApp,
      isSecondary: dynamicRateChangeWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipDynamicRateChange }
  );

  useEffect(() => {
    if (monthSuccess && (monthEndWhatsApp || monthEndWhatsAppAdmin)) {
      setMonthEndWhatsApp(false);
      setMonthEndWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [monthLoading, monthFetching, monthSuccess]);
  useEffect(() => {
    if (dynamicRateChangeSuccess && (dynamicRateChangeWhatsApp || dynamicRateChangeWhatsAppAdmin)) {
      setDynamicRateChangeWhatsApp(false);
      setDynamicRateChangeWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [dynamicRateChangeLoading, dynamicRateChangeFetching, dynamicRateChangeSuccess]);
  const {
    data: yearEndData,
    isLoading: yearLoading,
    isFetching: yearFetching,
  } = useGetYearEndReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: yearDateRange[0],
      endDate: yearDateRange[1],
      isPrimary: yearEndWhatsApp,
      isSecondary: yearEndWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipYear }
  );
  const {
    data: quarterlyData,
    isLoading: quarterlyLoading,
    isFetching: quaterlyFetching,
    refetch: quaterlyRefetch,
    isSuccess: quaterlySuccess,
  } = useGetQuarterlyReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: quarterlyDateRange[0],
      endDate: quarterlyDateRange[1],
      isPrimary: quaterlyWhatsApp,
      isSecondary: QuaterlyWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipQuarterly }
  );
  useEffect(() => {
    if (quaterlySuccess && (quaterlyWhatsApp || QuaterlyWhatsAppAdmin)) {
      setQuaterlyWhatsAppAdmin(false);
      setQuaterlyWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [quarterlyLoading, quaterlyFetching, quaterlySuccess]);
  const [ratesDateRange, setRatesDateRange] = useState([
    {
      startDate: paramsStartDate ? formatDate(
        new Date(paramsStartDate)
      ) : new Date(
        newDate.getFullYear(),
        newDate.getMonth() - 1,
        newDate.getDate() - 10
      ),
      endDate: paramsEndDate ? formatDate(
        new Date(paramsEndDate)
      ) : new Date(
        newDate.getFullYear(),
        newDate.getMonth() - 1,
        newDate.getDate() + 10
      ), // Setting endDate to 10 days ago from today
      id: "range 1",
    },
  ]);
  useEffect(() => {
    if (ratePulseDateRange?.fromDate) {
      const startDate = new Date(ratePulseDateRange.toDate);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 30);
      setParityDateRange([
        {
          startDate: startDate,
          endDate: endDate,
          id: "range 1",
        },
      ]);
    }
  }, [ratePulseDateRange]);
  const {
    data: sourceData,
    isLoading: sourceLoading,
    isFetching: sourceFetching,
    isSuccess: sourceSuccess,
  } = useGetSourceReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: sourceDateRange[0].startDate,
      endDate: sourceDateRange[0].endDate,
      isPrimary: sourceWhatsApp,
      isSecondary: sourceWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipSource }
  );
  useEffect(() => {
    if (sourceSuccess && (sourceWhatsApp || sourceWhatsAppAdmin)) {
      setSourceWhatsApp(false);
      setSourceWhatsAppAdmin(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [sourceLoading, sourceFetching, sourceSuccess]);

  const {
    data: segmentData,
    isFetching: segmentFetching,
    isLoading: segmentLoading,
  } = useGetSegmentReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(segmentDate),
      isPrimary: segmentWhatsApp,
    },
    { refetchOnMountOrArgChange: true, skip: true }
  );
  const {
    data: parityData,
    isFetching: parityFetching,
    isLoading: parityLoading,
    isSuccess: paritySuccess,
  } = useGetParityReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(parityDateRange[0].startDate),
      endDate: formatDate(parityDateRange[0].endDate),
      isPrimary: parityWhatsApp,
      isSecondary: parityWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipParity }
  );
  const {
    data: ratesData,
    isFetching: ratesFetching,
    isLoading: ratesLoading,
    isSuccess: ratesSuccess,
  } = useGetRatesReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: formatDate(ratesDateRange[0].startDate),
      endDate: formatDate(ratesDateRange[0].endDate),
      isPrimary: ratesWhatsApp,
      isSecondary: ratesWhatsAppAdmin,
    },
    { refetchOnMountOrArgChange: true, skip: skipRate }
  );

  const {
    data: cancelllationData,
    isFetching: cancellationIsFetching,
    isLoading: cancellationIsLoading,
    isSuccess: cancellationSuccess,
  } = useGetRateChangeReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: format(
        new Date(cancellationDateRange[0]?.startDate),
        "yyyy-MM-dd"
      ),
      endDate: format(
        new Date(cancellationDateRange[0]?.endDate),
        "yyyy-MM-dd"
      ),
      isPrimary: cancellationWhatsapp,
      isSecondary: cancellationWhatsAppAdmin
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipCancellation,
    }
  );

  const {
    data: revenueDeckReport,
    isFetching: revenueDeckReportIsFetching,
    isLoading: revenueDeckReportIsLoading,
    isSuccess: revenueDeckReportSuccess,
  } = useGetRevenueDeckReportQuery({
    userId: UserId(),
    hId: CurrentProperty(),
    startDate: format(selectedRevenueDeckDate, "yyy-MM-dd"),
    isPrimary: revenueDeckWhatsApp,
  });

  useEffect(() => {
    if (cancellationSuccess && (cancellationWhatsapp || cancellationWhatsAppAdmin)) {
      setCancellationWhatsAppAdmin(false);
      setCancellationWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [cancellationIsLoading, cancellationIsFetching, cancellationSuccess]);



  const {
    data: getInventory,
    isFetching: inventoryFetching,
    isLoading: inventoryLoading,
  } = useGetInventoryQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      asPer: format(selectedInventryDateRange, "yyy-MM-dd"),
      asOn: format(new Date(), "yyy-MM-dd"),
      isPrimary: inventoryWhatsApp,
      isSecondary: inventoryWhatsAppAdmin,
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipInventory,
    }
  );
  /* -------------------------------------------------------------------------- */
  /*                             rate change queries                            */
  /* -------------------------------------------------------------------------- */
  const {
    data: actualRateChangeReportData,
    isFetching: actualRateChangeReportFetching,
    isLoading: actualRateChangeReportLoading,
    isSuccess: actualRateChangeReportSuccess,
  } = useGetActualRateChangeReportQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      date: format(selectedDate, "yyy-MM-dd"),
      bifercationType: bifercationType,
      // asPer: format(selectedInventryDateRange, "yyy-MM-dd"),
      // asOn: format(new Date(), "yyy-MM-dd"),
      isPrimary: rateChangeWhatsApp,
      isSecondary: rateChangeWhatsAppAdmin
      // admin: inventoryWhatsAppAdmin,
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      // refetchOnMountOrArgChange: true,
      // skip: skipActualRateChange,
    }
  );
  useEffect(() => {
    if (actualRateChangeReportSuccess && (rateChangeWhatsApp || rateChangeWhatsAppAdmin)) {
      setRateChangeWhatsApp(false);
      setRateChangeWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [actualRateChangeReportLoading, actualRateChangeReportFetching, actualRateChangeReportSuccess]);
  const {
    data: useArcrIdMetricsData,
    isFetching: useArcrIdMetricsFetching,
    isLoading: useArcrIdMetricsLoading,
  } = useArcrIdMetricsDataQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      date: format(selectedDate, "yyy-MM-dd"),
      bifercationType: bifercationType,
      dateRangeType: metricDateRangeSelector,
      source: metricSourceSelector,
      // asPer: format(selectedInventryDateRange, "yyy-MM-dd"),
      // asOn: format(new Date(), "yyy-MM-dd"),
      // whatsAppNotify: inventoryWhatsApp,
      // admin: inventoryWhatsAppAdmin,
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipArcrIdMetricsChange,
    }
  );
  const {
    data: useArcrIdPaceData,
    isFetching: useArcrIdPaceFetching,
    isLoading: useArcrIdPaceLoading,
  } = useArcrIdPaceDataQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      date: format(selectedDate, "yyy-MM-dd"),
      bifercationType: bifercationType,
      dateRangeType: paceDateRangeSelector,
      source: paceSourceSelector,
      // asPer: format(selectedInventryDateRange, "yyy-MM-dd"),
      // asOn: format(new Date(), "yyy-MM-dd"),
      // whatsAppNotify: inventoryWhatsApp,
      // admin: inventoryWhatsAppAdmin,
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipArcrIdPaceChange,
    }
  );
  const {
    data: useArcrEdRateData,
    isFetching: useArcrEdRateFetching,
    isLoading: useArcrEdRateLoading,
  } = useArcrEdRateDataQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      date: format(selectedDate, "yyy-MM-dd"),
      bifercationType: bifercationType,
      // asPer: format(selectedInventryDateRange, "yyy-MM-dd"),
      // asOn: format(new Date(), "yyy-MM-dd"),
      // whatsAppNotify: inventoryWhatsApp,
      // admin: inventoryWhatsAppAdmin,
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipArcrEdRateChange,
    }
  );
  const {
    data: useArcrEdRateFluctuationData,
    isFetching: useArcrEdRateFluctuationFetching,
    isLoading: useArcrEdRateFluctuationLoading,
  } = useArcrEdRateFluctuationDataQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      date: format(selectedDate, "yyy-MM-dd"),
      bifercationType: bifercationType,
      dateRangeType: fluctuationDateRangeType.value,
      // asPer: format(selectedInventryDateRange, "yyy-MM-dd"),
      // asOn: format(new Date(), "yyy-MM-dd"),
      // whatsAppNotify: inventoryWhatsApp,
      // admin: inventoryWhatsAppAdmin,
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipArcrEdRateFluctuationChange,
    }
  );
  const {
    data: useArcrEdReputationRankReviewData,
    isFetching: useArcrEdReputationRankReviewFetching,
    isLoading: useArcrEdReputationRankReviewLoading,
  } = useArcrEdReputationRankReviewDataQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      date: format(selectedDate, "yyy-MM-dd"),
      bifercationType: bifercationType,
      // asPer: format(selectedInventryDateRange, "yyy-MM-dd"),
      // asOn: format(new Date(), "yyy-MM-dd"),
      // whatsAppNotify: inventoryWhatsApp,
      // admin: inventoryWhatsAppAdmin,
      // otaId: selectOta?.join(", "),
      // mealPlanId: selectMealPlan?.join(", "),
      // otaId: selectOta && selectOta?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: skipArcrEdReputationRankReviewChange,
    }
  );

  /* -------------------------------------------------------------------------- */
  /*                             parity report query                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (paritySuccess && (parityWhatsApp || parityWhatsAppAdmin)) {
      setParityWhatsAppAdmin(false);
      setParityWhatsApp(false);
      toast.success("Report send to whatsapp successfull", {
        position: "bottom-right",
      });
    }
  }, [parityLoading, parityFetching, paritySuccess]);


  const handleDownload = () => {
    if (reportId == "moenrep") {
      DownloadMonthEndReport(monthEndData?.data);
    } else if (reportId == "parep") {
      DownloadPaceReport(paceData);
    } else if (reportId == "revrep") {
      DownloadRevenueReport(revenueData?.revenueReport);
    } else if (reportId == "segrep") {
      DownloadSegmentReport(segmentData?.data);
    } else if (reportId == "sourep") {
      DownloadSourceReport(sourceData?.data[0]);
    } else if (reportId == "quarep") {
      DownloadQuaterlyReport(quarterlyData?.data);
    } else if (reportId == "parrep") {
      DownloadParityReport(parityData?.data, parityData?.asOn);
    } else if (reportId == "yeenrep") {
      YearEndReportdownload(yearEndData?.data);
    } else if (reportId == "rachrep") {
      DownloadCancellationReport(
        cancelllationData?.rateChangeReport?.cancellationData
      );
    } else if (reportId == "dyrachrep") {
      DownloadDynamicRateChangeReport(dynamicRateChangeData);
    } else if (reportId == "comprarep") {
      DownloadCompsetReport(compsetReportData?.excelData, compsetReportData?.hotelName, compsetReportData?.dayStart);
    } else if (reportId == "hoprorep") {
      DownloadHotelProductivityReport(hotelProductivityReportData?.data?.excelData, hotelProductivityReportData?.data?.hotelName, hotelProductivityReportData?.data?.firstDayOfYear, hotelProductivityReportData?.data?.lastDayOfYear);
    } else if (reportId == "horederep") {
      DownloadRevenueDeckReportExcel(hotelProductivityReportData?.data?.excelData, hotelProductivityReportData?.data?.hotelName, hotelProductivityReportData?.data?.firstDayOfYear, hotelProductivityReportData?.data?.lastDayOfYear);
    }

  };



  //setting start date and end date from params
  useEffect(() => {
    if (reportId === "moenrep") {

      const params = new URLSearchParams();
      if (monthEndDateRange[0]) params.set('startDate', monthEndDateRange[0]);
      if (monthEndDateRange[1]) params.set('endDate', monthEndDateRange[1]);
      navigate(`/Report/TemplateReport/moenrep?${params.toString()}`, { replace: true });
    }
  }, [monthEndDateRange]);
  useEffect(() => {
    if (reportId === "quarep") {

      const params = new URLSearchParams();
      if (quarterlyDateRange[0]) params.set('startDate', quarterlyDateRange[0]);
      if (quarterlyDateRange[1]) params.set('endDate', quarterlyDateRange[1]);
      navigate(`/Report/TemplateReport/quarep?${params.toString()}`, { replace: true });
    }
  }, [quarterlyDateRange]);
  useEffect(() => {
    if (reportId === "yeenrep") {

      const params = new URLSearchParams();
      if (yearDateRange[0]) params.set('startDate', yearDateRange[0]);
      if (yearDateRange[1]) params.set('endDate', yearDateRange[1]);
      navigate(`/Report/TemplateReport/yeenrep?${params.toString()}`, { replace: true });
    }
  }, [yearDateRange]);
  useEffect(() => {
    if (reportId === "parep") {

      const params = new URLSearchParams();
      if (paceDateRange[0]) params.set('startDate', paceDateRange[0]?.startDate);
      if (paceDateRange[0]) params.set('endDate', paceDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/parep?${params.toString()}`, { replace: true });
    }
  }, [paceDateRange]);
  useEffect(() => {
    if (reportId === "sourep") {

      const params = new URLSearchParams();
      if (sourceDateRange[0]) params.set('startDate', isDate(sourceDateRange[0]?.startDate) ? formatDate(new Date(sourceDateRange[0]?.startDate)) : sourceDateRange[0]?.startDate);
      if (sourceDateRange[0]) params.set('endDate', isDate(sourceDateRange[0]?.endDate) ? formatDate(new Date(sourceDateRange[0]?.endDate)) : sourceDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/sourep?${params.toString()}`, { replace: true });
    }
  }, [sourceDateRange]);
  useEffect(() => {
    if (reportId === "revrep") {

      const params = new URLSearchParams();
      if (sourceDateRange[0]) params.set('startDate', isDate(revenueDateRange[0]?.startDate) ? formatDate(new Date(revenueDateRange[0]?.startDate)) : revenueDateRange[0]?.startDate);
      if (sourceDateRange[0]) params.set('endDate', isDate(revenueDateRange[0]?.endDate) ? formatDate(new Date(revenueDateRange[0]?.endDate)) : revenueDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/revrep?${params.toString()}`, { replace: true });
    }
  }, [revenueDateRange]);
  useEffect(() => {
    if (reportId === "revrep") {

      const params = new URLSearchParams();
      if (revenueDateRange[0]) params.set('startDate', isDate(revenueDateRange[0]?.startDate) ? formatDate(new Date(revenueDateRange[0]?.startDate)) : revenueDateRange[0]?.startDate);
      if (revenueDateRange[0]) params.set('endDate', isDate(revenueDateRange[0]?.endDate) ? formatDate(new Date(revenueDateRange[0]?.endDate)) : revenueDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/revrep?${params.toString()}`, { replace: true });
    }
  }, [revenueDateRange]);
  useEffect(() => {
    if (reportId === "ratrep") {

      const params = new URLSearchParams();
      if (ratesDateRange[0]) params.set('startDate', isDate(ratesDateRange[0]?.startDate) ? formatDate(new Date(ratesDateRange[0]?.startDate)) : ratesDateRange[0]?.startDate);
      if (ratesDateRange[0]) params.set('endDate', isDate(ratesDateRange[0]?.endDate) ? formatDate(new Date(ratesDateRange[0]?.endDate)) : ratesDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/ratrep?${params.toString()}`, { replace: true });
    }
  }, [ratesDateRange]);
  useEffect(() => {
    if (reportId === "pickrep") {

      const params = new URLSearchParams();
      if (pickUpDateRange[0]) params.set('startDate', isDate(pickUpDateRange[0]?.startDate) ? formatDate(new Date(pickUpDateRange[0]?.startDate)) : pickUpDateRange[0]?.startDate);
      if (pickUpDateRange[0]) params.set('endDate', isDate(pickUpDateRange[0]?.endDate) ? formatDate(new Date(pickUpDateRange[0]?.endDate)) : pickUpDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/pickrep?${params.toString()}`, { replace: true });
    }
  }, [pickUpDateRange]);
  useEffect(() => {
    if (reportId === "canrep") {

      const params = new URLSearchParams();
      if (cancellationDateRange[0]) params.set('startDate', isDate(cancellationDateRange[0]?.startDate) ? formatDate(new Date(cancellationDateRange[0]?.startDate)) : cancellationDateRange[0]?.startDate);
      if (cancellationDateRange[0]) params.set('endDate', isDate(cancellationDateRange[0]?.endDate) ? formatDate(new Date(cancellationDateRange[0]?.endDate)) : cancellationDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/canrep?${params.toString()}`, { replace: true });
    }
  }, [cancellationDateRange]);
  useEffect(() => {
    if (reportId === "dyrachrep") {

      const params = new URLSearchParams();
      if (dynamicRateChangeDateRange[0]) params.set('startDate', isDate(dynamicRateChangeDateRange[0]?.startDate) ? formatDate(new Date(dynamicRateChangeDateRange[0]?.startDate)) : dynamicRateChangeDateRange[0]?.startDate);
      if (dynamicRateChangeDateRange[0]) params.set('endDate', isDate(dynamicRateChangeDateRange[0]?.endDate) ? formatDate(new Date(dynamicRateChangeDateRange[0]?.endDate)) : dynamicRateChangeDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/dyrachrep?${params.toString()}`, { replace: true });
    }
  }, [dynamicRateChangeDateRange]);
  useEffect(() => {
    if (reportId === "parrep") {

      const params = new URLSearchParams();
      if (parityDateRange[0]) params.set('startDate', isDate(parityDateRange[0]?.startDate) ? formatDate(new Date(parityDateRange[0]?.startDate)) : parityDateRange[0]?.startDate);
      if (parityDateRange[0]) params.set('endDate', isDate(parityDateRange[0]?.endDate) ? formatDate(new Date(parityDateRange[0]?.endDate)) : parityDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/parrep?${params.toString()}`, { replace: true });
    }
  }, [parityDateRange]);
  useEffect(() => {

    if (reportId === "comprarep") {
      const params = new URLSearchParams();
      if (compsetReportDateRange[0]) params.set('startDate', isDate(compsetReportDateRange[0]?.startDate) ? formatDate(new Date(compsetReportDateRange[0]?.startDate)) : compsetReportDateRange[0]?.startDate);
      if (compsetReportDateRange[0]) params.set('endDate', isDate(compsetReportDateRange[0]?.endDate) ? formatDate(new Date(compsetReportDateRange[0]?.endDate)) : compsetReportDateRange[0]?.endDate);
      navigate(`/Report/TemplateReport/comprarep?${params.toString()}`, { replace: true });
    }
  }, [compsetReportDateRange]);
  useEffect(() => {

    if (reportId === "rachrepra") {
      const params = new URLSearchParams();
      if (selectedDate) params.set('startDate', isDate(selectedDate) ? formatDate(new Date(selectedDate)) : selectedDate);
      navigate(`/Report/TemplateReport/rachrepra?${params.toString()}`, { replace: true });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (reportId === "horederep") {
      const params = new URLSearchParams();
      if (selectedRevenueDeckDate) params.set('startDate', isDate(selectedRevenueDeckDate) ? formatDate(new Date(selectedRevenueDeckDate)) : selectedRevenueDeckDate);
      navigate(`/Report/TemplateReport/horederep?${params.toString()}`, { replace: true });
    }
  }, [selectedRevenueDeckDate]);



  return (
    <div className="p-4">
      <div className=" flex">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className={`bg-[#1D2D44] w-fit  flex items-center justify-center cursor-pointer rounded-lg  h-[36px] py-1 px-3 `}
        >
          <img className="" src={BackButton} alt="." />
        </div>
        {/* <h1
          onClick={handleDownload}
          className=" flex m-auto   justify-center items-center rounded-lg cursor-pointer dark:bg-[#1D3893] bg-[#1D2D44] h-[40px] mr-2 p-4 text-white dark:text-mainDark font-medium text-[17px]"
        >
          Download
        </h1>{" "} */}
      </div>
      <div className="">
        {reportId == "moenrep" && (
          <MonthEndTable
            data={monthEndData?.data}
            isLoading={monthFetching || monthLoading}
            dateRange={monthEndDateRange}
            setDateRange={setMonthEndDateRange}
            setIsWhatsApp={() => {
              setMonthEndWhatsApp(true);
              // setMonthEndWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setMonthEndWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId === "dyrachrep" && (
          <DynamicRateChangeReport
            data={dynamicRateChangeData?.data}
            isLoading={dynamicRateChangeFetching || dynamicRateChangeLoading}
            dateRange={dynamicRateChangeDateRange}
            setDateRange={setDynamicRateChangeDateRange}
            setIsWhatsApp={() => {
              setDynamicRateChangeWhatsApp(true);
              // setMonthEndWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setDynamicRateChangeWhatsAppAdmin(true);
            }}
            handleDownload={handleDownload}
          />
        )}
        {reportId == "parep" && (
          <PaceReport
            data={paceData}
            setDateRange={setPaceDateRange}
            dateRange={paceDateRange}
            isLoading={paceLoading || paceFetching}
            setIsWhatsApp={() => {
              setPaceWhatsApp(true);
              // setPaceWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setPaceWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId == "revrep" && (
          <RevenueReportTable
            dateRange={revenueDateRange}
            setDateRange={setRevenueDateRange}
            data={revenueData?.revenueReport}
            isLoading={revenueIsFetching || revenueLoading}
            setIsWhatsApp={() => {
              setRevenueWhatsApp(true);
              // setRevenueWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setRevenueWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId == "segrep" && (
          <SegmentReport
            data={segmentData?.data}
            selected2Date={segmentDate}
            setSelected2Date={setSegmentDate}
            setIsWhatsApp={() => {
              setSegmentWhatsApp(true);
              // setPaceWhatsApp(false);
            }}
            isLoading={segmentFetching || segmentLoading}
          />
        )}
        {reportId == "sourep" && (
          <SourceReport
            setDateRange={setSourceDateRange}
            dateRange={sourceDateRange}
            data={sourceData?.data}
            isLoading={sourceLoading || sourceFetching}
            setIsWhatsApp={() => {
              setSourceWhatsApp(true);
              // setSourceWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setSourceWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId == "quarep" && (
          <QuarterlyReport
            isLoading={quarterlyLoading || quaterlyFetching}
            dateRange={quarterlyDateRange}
            setDateRange={setQuaterlyDateRange}
            data={quarterlyData?.data}
            setIsWhatsApp={() => {
              setQuaterlyWhatsApp(true);
              // setQuaterlyWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setQuaterlyWhatsAppAdmin(true);
            }}
            QuaterlyWhatsAppAdmin={QuaterlyWhatsAppAdmin}
          />
        )}
        {reportId == "parrep" && (
          <ParityReport
            setDateRange={setParityDateRange}
            dateRange={parityDateRange}
            data={parityData?.data}
            isLoading={parityLoading || parityFetching}
            setIsWhatsApp={() => {
              setParityWhatsApp(true);
              // setParityWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setParityWhatsAppAdmin(true);
            }}
            asOn={parityData?.asOn}
          />
        )}
        {reportId == "ratrep" && (
          <RatesReport
            setDateRange={setRatesDateRange}
            dateRange={ratesDateRange}
            data={ratesData?.data}
            isLoading={ratesLoading || ratesFetching}
            setIsWhatsApp={() => {
              setRatesWhatsApp(true);
              // setParityWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setRatesWhatsAppAdmin(true);
            }}
            parityWhatsAppAdmin={ratesWhatsAppAdmin}
            asOn={ratesData?.asOn}
          />
        )}

        {reportId == "pickrep" && (
          <PickUpReport
          selected2Date={pickUpDate}
          setSelected2Date={setPickupDate}
            data={pickUpData?.data}
            setDateRange={setPickUpDateRange}
            dateRange={pickUpDateRange}
            isLoading={pickupLoading || pickupFetching}
            setIsWhatsApp={() => {
              setPickUpWhatsApp(true);
              // setParityWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setPickUpWhatsAppAdmin(true);
            }}
            pickUpWhatsApp={pickUpWhatsApp}
          />
        )}

        {reportId == "yeenrep" && (
          <YearEndReport
            setDateRange={setYearDateRange}
            dateRange={yearDateRange}
            data={yearEndData?.data}
            isLoading={yearLoading || yearFetching}
            setIsWhatsApp={() => {
              setYearEndWhatsApp(true);
              // setYearEndWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setYearEndWhatsAppAdmin(true);
            }}
          />
        )}

        {reportId == "occrep" && (
          <OccupencyReport
            getInventory={getInventory}
            isLoading={inventoryLoading || inventoryFetching}
            selectedDate={selectedInventryDateRange}
            setSelectedDate={setSelectedInventryDateRange}
            setIsWhatsApp={() => {
              setInventoryWhatsApp(true);
              // setYearEndWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setInventoryWhatsAppAdmin(true);
            }}
          />
        )}
        {reportId === "canrep" && (
          <CancellationReport
            data={cancelllationData}
            isLoading={cancellationIsFetching || cancellationIsLoading}
            setDateRange={setCancellationDateRange}
            dateRange={cancellationDateRange}
            setIsWhatsApp={() => {
              setCancellationWhatsApp(true);
              // setParityWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setCancellationWhatsAppAdmin(true);
              // setParityWhatsApp(false);
            }}
          />
        )}
        {reportId === "rachrepra" && (
          <RateChangeReport
            metricData={useArcrIdMetricsData}
            isMetricDataLoading={
              useArcrIdMetricsLoading || useArcrIdMetricsLoading
            }
            metricSourceSelector={metricSourceSelector}
            metricDateRangeSelector={metricDateRangeSelector}
            setMetricSourceSelector={setMetricSourceSelector}
            setMetricDateRangeSelector={setMetricDateRangeSelector}
            paceSourceSelector={paceSourceSelector}
            setPaceSourceSelector={setPaceSourceSelector}
            paceDateRangeSelector={paceDateRangeSelector}
            setPaceDateRangeSelector={setPaceDateRangeSelector}
            fluctuationDateRangeType={fluctuationDateRangeType}
            setFluctuationDateRangeType={setFluctuationDateRangeType}
            paceData={useArcrIdPaceData}
            isPaceDataLoading={useArcrIdPaceFetching || useArcrIdPaceLoading}
            rateData={useArcrEdRateData}
            isRateDataLoading={useArcrEdRateLoading || useArcrEdRateFetching}
            fluctuationData={useArcrEdRateFluctuationData}
            isFluctuationDataLoading={
              useArcrEdRateFluctuationFetching ||
              useArcrEdRateFluctuationLoading
            }
            reputationData={useArcrEdReputationRankReviewData}
            isReputationDataLoading={
              useArcrEdReputationRankReviewLoading ||
              useArcrEdReputationRankReviewFetching
            }
            setDateRange={setSelectedDate}
            dateRange={selectedDate}
            bifercationType={bifercationType}
            setBifercationType={setBifercationType}
            setIsWhatsApp={() => {
              setRateChangeWhatsApp(true);
              // setParityWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setRateChangeWhatsAppAdmin(true);
              // setParityWhatsApp(false);
            }}
          />
        )}

        {reportId == "comprarep" && (
          <CompsetReport
            setDateRange={setCompsetReportDateRange}
            dateRange={compsetReportDateRange}
            data={compsetReportData?.data}
            isLoading={compsetReportLoading || compsetReportFetching}
            setIsWhatsApp={() => {
              setCompsetReportWhatsApp(true);
              // setParityWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setCompsetReportWhatsAppAdmin(true);
            }}
            parityWhatsAppAdmin={compsetReportWhatsAppAdmin}
            asOn={compsetReportData?.asOn}
            handleDownload={handleDownload}
          />
        )}

        {reportId == "hoprorep" && (
          <HotelProductivityReportTable
            setDateRange={setHotelProductivityDateRange}
            dateRange={hotelProductivityDateRange}
            data={hotelProductivityReportData?.data?.tableData}
            isLoading={hotelProductivityReportLoading || hotelProductivityReportFetching}
            setIsWhatsApp={() => {
              setHotelProductivityWhatsApp(true);
              // setYearEndWhatsApp(false);
            }}
            sendToWhatsappAdmin={() => {
              setHotelProductivityReportAdmin(true);
            }}
            handleDownload={handleDownload}
          />
        )}

        {reportId == "horederep" && (
          <RevenueDeckReport
            data={revenueDeckReport}
            isLoading={
              revenueDeckReportIsFetching || revenueDeckReportIsLoading
            }
            dateRange={selectedRevenueDeckDate}
            setDateRange={setSelectedRevenueDeckDate}
            setIsWhatsApp={() => {
              setRateChangeWhatsApp(true);
              // setMonthEndWhatsApp(false);
            }}
            handleDownload={() => {
              DownloadRevenueDeckReportExcel(revenueDeckReport?.excelData, revenueDeckReport?.hotelName, revenueDeckReport?.dayStart);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ReportId;
