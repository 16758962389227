import React, { useEffect, useState } from "react";
import SettingButton from "../../../../components/settingButton/SettingButton";
import {
  useEditCMRateChargeRuleMutation,
  useGetCMRateChargeRuleQuery,
  useGetCMRoomDetailQuery,
} from "../../../../redux/slices/settings/channelManager/cmRuleMapping";
import {
  ChannelManagerDetail,
  CurrentProperty,
} from "../../../../utils/impVars";
import Dropdown from "../../../../components/dropdown";
import { AiFillDelete } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { MdDeleteOutline } from "react-icons/md";
import CMRoomMappin from "./subComponent/cmRoomMapping";
import CMRoomPlanMapping from "./subComponent/cmRoomPlanMapping";
import CMOccupancyMapping from "./subComponent/cmOccupancyMapping";

const CM_RuleMapping = ({ handleBackButtonClick, handleAddRatePlanClick }) => {
  const [selectedCMOption, setSelectedCMOption] = useState();
  const [selectedRoomOption, setSelectedRoomOption] = useState();
  const [selectedRoomPrice, setSelectedRoomPrice] = useState();
  const [roomsClone, setRoomsClone] = useState();

  const [isRoomSelected, setIsRoomSelected] = useState(false);
  const [isRoomPlanSelected, setIsRoomPlanSelected] = useState(false);
  const [selectedPlanRoom, setSelectedPlanRoom] = useState("");
  const [selectedPlanRoomId, setSelectedPlanRoomId] = useState(0);
  const [selectedOccupancyPlan, setSelectedOccupancyPlan] = useState("");
  const [selectedOccupancyPlanId, setSelectedOccupancyPlanId] = useState(0);

  const [editCMRateChargeRule] = useEditCMRateChargeRuleMutation();

  // const {
  //   data: cmRateChargeRule,
  //   refetch,
  // } = useGetCMRateChargeRuleQuery(
  //   {
  //     hId: CurrentProperty(),
  //     channelId: selectedCMOption
  //   },
  //   {
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  const [ruleMappingData, setRuleMappingData] = useState({
    hId: CurrentProperty(),
    channelId: "0",
    masterRoom: {
      id: {
        value: "",
        label: "",
      },
      price: 0,
    },
    rooms: [],
  });

  useEffect(() => {
    console.log(ruleMappingData, "aman rule mpping");
  }, [ruleMappingData]);

  useEffect(() => {
    let test = JSON.parse(JSON.stringify(ruleMappingData));
    test.masterRoom = { ...test.masterRoom, id: selectedRoomOption };
    console.log(selectedRoomOption, "aman selectedRoomOption");
    if (selectedRoomOption && selectedRoomOption?.label !== "") {
      if (test.rooms.length === 0) {
        test.rooms = [
          ...test.rooms,
          {
            masterId: selectedRoomOption?.label,
            derivedId: {
              roomId: selectedRoomOption?.value,
              roomName: selectedRoomOption?.label,
            },
            adjust: {
              adjustmentType: "percent",
              amountChangeType: "increase",
              adjustmentValue: 0,
            },
            masterRatePlan: {
              masterId: "",
              ratePlan: [],
            },
          },
        ];
      } else {
        test.rooms[0].masterId = selectedRoomOption?.label
        test.rooms[0].derivedId = {
          roomId: selectedRoomOption?.value,
          roomName: selectedRoomOption?.label
        };
      }
      test.rooms.map((rooms, roomIndex) => {
        test.rooms[roomIndex].masterId = selectedRoomOption?.label;
      });
      setRoomsClone(test); // Update roomsClone as well
    }
    setRuleMappingData(test); // Set directly
  }, [selectedRoomOption]);

  useEffect(() => {
    let test = JSON.parse(JSON.stringify(ruleMappingData));
    test.masterRoom = { ...test.masterRoom, price: selectedRoomPrice };
    setRuleMappingData(test);
  }, [selectedRoomPrice]);

  const {
    data: getCM_RoomDetails,
    isLoading,
    isFetching,
  } = useGetCMRoomDetailQuery({
    hId: CurrentProperty(),
  });

  const { data: getCM_ChargeRules, refetch } = useGetCMRateChargeRuleQuery(
    {
      hId: CurrentProperty(),
      channelId: selectedCMOption?.value || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [selectedCMOption]);

  const currentHID = CurrentProperty();
  useEffect(() => {
    if (getCM_ChargeRules && getCM_ChargeRules?.data) {
      setRuleMappingData(getCM_ChargeRules?.data);
      setSelectedRoomPrice(getCM_ChargeRules?.data?.masterRoom?.price);
    } else {
      setRuleMappingData({
        hId: currentHID,
        channelId: "0",
        masterRoom: {
          id: {
            value: "",
            label: "",
          },
          price: 0,
        },
        rooms: [],
      });
    }
  }, [getCM_ChargeRules]);

  const channelManagerOption = getCM_RoomDetails?.data?.info?.map(
    (item, index) => {
      return {
        label: item?.channelName,
        value: item?.channelCode,
      };
    }
  );

  useEffect(() => {
    setSelectedCMOption({
      value: getCM_RoomDetails?.data?.info?.[0]?.channelCode,
      label: getCM_RoomDetails?.data?.info?.[0]?.channelName,
    });
  }, [getCM_RoomDetails]);

  const handleAddRoomData = () => {
    if (
      !ruleMappingData.masterRoom.id ||
      ruleMappingData.masterRoom.id === "" ||
      !ruleMappingData.masterRoom.price ||
      ruleMappingData.masterRoom.price === 0
    ) {
      toast.error(`Select Master Room & enter its price first.`, {
        position: "bottom-right",
      });
    } else {
      // Create a copy of the current ruleMappingData
      let updatedData = JSON.parse(JSON.stringify(ruleMappingData));

      updatedData.rooms = [
        ...updatedData.rooms,
        {
          masterId: ruleMappingData?.masterRoom?.id?.label,
          derivedId: "",
          adjust: {
            adjustmentType: "percent",
            amountChangeType: "increase",
            adjustmentValue: 0,
          },
          masterRatePlan: {
            masterId: "",
            ratePlan: [],
          },
        },
      ];
      setRuleMappingData(updatedData); // Set directly
      setRoomsClone(updatedData); // Update roomsClone as well
    }
  };
  const handleAddRoomPlanData = () => {
    if (
      !ruleMappingData.rooms[selectedPlanRoomId].masterRatePlan ||
      ruleMappingData.rooms[selectedPlanRoomId].masterRatePlan.masterId ===
      "" ||
      ruleMappingData.rooms[selectedPlanRoomId].masterRatePlan.masterId === 0
    ) {
      toast.error(`Select Master Room Plan`, {
        position: "bottom-right",
      });
    } else {
      // Create a copy of the current ruleMappingData
      let updatedData = JSON.parse(JSON.stringify(ruleMappingData));

      updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan = [
        ...updatedData.rooms[selectedPlanRoomId].masterRatePlan.ratePlan,
        {
          masterId:
            updatedData.rooms[selectedPlanRoomId].masterRatePlan.masterId,
          derivedId: "",
          adjust: {
            adjustmentType: "percent",
            amountChangeType: "increase",
            adjustmentValue: 0,
          },
          masterOccupancy: "",
          occupancyPlan: [],
        },
      ];
      setRuleMappingData(updatedData); // Set directly
      setRoomsClone(updatedData); // Update roomsClone as well
    }
  };

  const handleRemoveRoomData = (index) => {
    let updatedData = JSON.parse(JSON.stringify(ruleMappingData));
    updatedData.rooms = updatedData.rooms.filter((_, idx) => idx !== index);

    setRuleMappingData(updatedData);
    setRoomsClone(updatedData);
  };

  const [roomOptions, setRoomOptions] = useState([]);

  useEffect(() => {
    let roomOptionsT =
      getCM_RoomDetails?.data?.info
        ?.filter((item) => item?.channelName === selectedCMOption?.label)
        ?.flatMap((item) =>
          item?.roomInfo?.map((itm) => ({
            label: itm?.roomName,
            value: itm?.roomId,
          }))
        ) || [];
    setRoomOptions(roomOptionsT);
  }, [getCM_RoomDetails, selectedCMOption]);

  useEffect(() => {
    let ruleMappingDataTest = ruleMappingData;

    setRuleMappingData();

    setRuleMappingData(ruleMappingDataTest);
  }, [ruleMappingData]);

  const handleChangeValue = (index, type, key, value) => {
    let updatedData = JSON.parse(JSON.stringify(ruleMappingData));
    if (type === "single") {
      updatedData.rooms = updatedData.rooms.map((room, idx) =>
        idx === index ? { ...room, [key]: value } : room
      );
    } else if (type === "nested") {
      updatedData.rooms = updatedData.rooms.map((room, idx) =>
        idx === index ? { ...room, [key]: value } : room
      );
    } else if (type === "adjust") {
      let adjust = {
        adjustmentType:
          key === "adjustmentType"
            ? value
            : updatedData?.rooms?.[index].adjust.adjustmentType,
        amountChangeType:
          key === "amountChangeType"
            ? value
            : updatedData?.rooms?.[index].adjust.amountChangeType,
        adjustmentValue:
          key === "adjustmentValue"
            ? value
            : updatedData?.rooms?.[index].adjust.adjustmentValue,
      };
      updatedData.rooms = updatedData.rooms.map((room, idx) =>
        idx === index ? { ...room, adjust: adjust } : room
      );
    }

    setRuleMappingData(updatedData);
    setRoomsClone(updatedData);
  };

  // useEffect(() => {
  //   setRuleMappingData(testRuleMappingData);
  // }, [testRuleMappingData]);

  const [roomNameMap, setRoomNameMap] = useState();
  const [roomIdMap, setRoomIdMap] = useState();

  useEffect(() => {
    const channelMap = new Map(
      getCM_RoomDetails?.data?.info?.map((entry, id) => [
        entry.channelName,
        entry,
      ])
    );
    const channelData = channelMap.get(selectedCMOption?.label);

    let roomsMapT = new Map(
      channelData?.roomInfo?.map((entry, id) => [entry.roomName, entry])
    );
    let roomsIdMapT = new Map(
      channelData?.roomInfo?.map((entry, id) => [entry.roomId, entry])
    );
    setRoomNameMap(roomsMapT);
    setRoomIdMap(roomsIdMapT);
  }, [selectedCMOption]);

  const roomMapColumns = [
    {
      name: "Derived Room",
      selector: (row) => row["derivedId"],
      cell: (row, index) => {
        return (
          <div>
            {row["derivedId"] && row["derivedId"] !== "" ? (
              <div className="flex flex-row">
                <p className="text-[12px] text-left">
                  {row["derivedId"]?.roomName}
                </p>
                {index !== 0 && (
                  <button
                    onClick={() => {
                      handleChangeValue(index, "single", "derivedId", "");
                    }}
                  >
                    <MdDeleteOutline className="text-white text-[20px]" />
                  </button>
                )}
              </div>
            ) : (
              <select
                onChange={(e) => {
                  let roomIdd = roomNameMap.get(e.target.value);
                  let roomObj = {
                    roomId: roomIdd.roomId,
                    roomName: roomIdd.roomName,
                  };
                  handleChangeValue(index, "single", "derivedId", roomObj);
                }}
                className="focus:outline-none text-black border-none outline-none py-0 px-2 w-[100%] bg-gray-200 my-2 rounded-md h-8  text-xs "
              >
                <option value="">Select room</option>
                {roomOptions &&
                  roomOptions
                    ?.filter((itm) => {
                      return itm.label !== selectedRoomOption?.label;
                    })
                    .map((item) => {
                      return <option key={item?.label}>{item?.label}</option>;
                    })}
              </select>
            )}
          </div>
        );
      },
    },
    {
      name: "Master Room",
      selector: (row) => row["masterId"],
      cell: (row) => {
        return (
          <div
            data-tooltip-content={row["masterId"]}
            data-tooltip-id={row["masterId"]}
            className="text-left text-[12px]"
          >
            {row["masterId"]}
            <Tooltip place="bottom" id={row["masterId"]} />
          </div>
        );
      },
    },
    {
      name: "Adjust",
      selector: (row) => row["adjust.adjustmentType"],
      cell: (row, index) => {
        return (
          <div className="flex gap-2">
            <button
              className={`${row?.adjust.adjustmentType === "percent" ? "bg-blue-800 text-white" : " text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(index, "adjust", "adjustmentType", "percent")
              }
              disabled={index === 0 ? true : false}
            >
              %
            </button>
            <button
              className={`${row?.adjust.adjustmentType === "amount" ? "bg-blue-800 text-white" : "text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(index, "adjust", "adjustmentType", "amount")
              }
              disabled={index === 0 ? true : false}
            >
              ₹
            </button>
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => row["adjust.amountChangeType"],
      cell: (row, index) => {
        return (
          <div className="flex gap-2">
            <button
              className={`${row?.adjust.amountChangeType === "decrease" ? "bg-blue-800 text-white " : "text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(
                  index,
                  "adjust",
                  "amountChangeType",
                  "decrease"
                )
              }
              disabled={index === 0 ? true : false}
            >
              -
            </button>
            <button
              className={`${row?.adjust.amountChangeType === "increase" ? "bg-blue-800 text-white " : "text-white"} cursor-pointer rounded-lg px-4 py-2`}
              onClick={() =>
                handleChangeValue(
                  index,
                  "adjust",
                  "amountChangeType",
                  "increase"
                )
              }
              disabled={index === 0 ? true : false}
            >
              +
            </button>
          </div>
        );
      },
    },
    {
      name: "Adjustment Value",
      selector: (row) => row["adjust.adjustmentValue"],
      cell: (row, index) => {
        return (
          <input
            className="py-2 rounded-lg px-1 text-sm focus:outline-none w-[70%] text-black"
            onChange={(e) =>
              handleChangeValue(
                index,
                "adjust",
                "adjustmentValue",
                e.target.value
              )
            }
            disabled={index === 0 ? true : false}
            value={row.adjust.adjustmentValue}
          />
        );
      },
    },
    {
      name: "Total Value",
      selector: (row) => row["totalValue"],
      cell: (row, index) => {
        let totalVal = applyRule(Number(selectedRoomPrice), row.adjust);
        return (
          <input
            className="py-2 rounded-lg px-1 text-sm focus:outline-none w-[70%] text-black"
            disabled={true}
            onChange={
              (e) => { }
              // handleChangeValue(index, "adjustValue", e.target.value)
            }
            value={totalVal}
          />
        );
      },
    },
    {
      name: "Actions",
      selector: (row) => row["derivedRoom"],
      cell: (row, index) => {
        return (
          <div className="flex flex-row gap-2 ">
            {channelManagerId !== 108 && (
              <button
                className=" text-white font-semibold text-[12px] text-left"
                onClick={() => {
                  if (
                    row["derivedId"]?.roomId &&
                    row["derivedId"]?.roomId !== ""
                  ) {
                    setIsRoomSelected(true);
                    setSelectedPlanRoom(row["derivedId"]?.roomId);
                    setSelectedPlanRoomId(index);
                  } else {
                    toast.error("Please select room first", {
                      position: "bottom-right",
                    });
                  }
                }}
              >{`Map Room Plan`}</button>
            )}
            <button
              className=""
              onClick={() => {
                handleRemoveRoomData(index);
              }}
            >
              <AiFillDelete className="text-red-700" />{" "}
            </button>
          </div>
        );
      },
    },
  ];

  function applyRule(baseRate, rule) {
    let adjustedRate = Number(baseRate);
    if (rule?.adjustmentType === "percent") {
      if (rule?.amountChangeType === "increase") {
        adjustedRate += (baseRate * Number(rule?.adjustmentValue)) / 100;
      } else if (rule?.amountChangeType === "decrease") {
        adjustedRate -= (baseRate * Number(rule?.adjustmentValue)) / 100;
      }
    } else if (rule?.adjustmentType === "amount") {
      if (rule?.amountChangeType === "increase") {
        adjustedRate += Number(rule?.adjustmentValue);
      } else if (rule?.amountChangeType === "decrease") {
        adjustedRate -= Number(rule?.adjustmentValue);
      }
    }
    adjustedRate = Math.ceil(adjustedRate);
    return adjustedRate;
  }
  useEffect(() => {
    roomsClone && setRuleMappingData(roomsClone);
  }, [roomsClone]);
  useEffect(() => {
    if (roomsClone?.rooms) {
      // debugger; // This should now hit when roomsClone.rooms changes
    }
  }, [roomsClone?.rooms]);

  let channelManagerDetail = ChannelManagerDetail();
  channelManagerDetail = JSON.parse(channelManagerDetail);
  let channelManagerId = channelManagerDetail?.["id"];
  function handleSave() {
    if (ruleMappingData.channelId !== "0") {
      editCMRateChargeRule(ruleMappingData)
        .unwrap()
        .then((res) => {
          toast.success("Rate Plan Updated Successfully!", {
            position: "bottom-right",
          });
          refetch();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (channelManagerId === 108 || channelManagerId === 106) {
      editCMRateChargeRule(ruleMappingData)
        .unwrap()
        .then((res) => {
          toast.success("Rate Plan Updated Successfully!", {
            position: "bottom-right",
          });
          refetch();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error(`Select source again on top right corner`, {
        position: "bottom-right",
      });
    }
  }


  return (
    <div className="flex flex-col gap-4 w-[100%]">
      <SettingButton
        Title={"Rate Plan"}
        table={true}
        handleBackButtonClick={handleBackButtonClick}
      />
      <div className="flex gap-2 w-[100%]">
        <div className="relative w-[100%]   bg-newGradient text-lightFontColor dark:bg-lightGradient flex flex-col rounded-xl p-4">
          <div className="flex items-center justify-between">
            <p className="text-[14px] font-[500]">
              Channel Manager Rule Mapping
            </p>
            <div className="flex items-center gap-2">
              <div className="bg-black/40 rounded-lg w-fit">
                {channelManagerId !== 108 && channelManagerId !== 106 && (
                  <Dropdown
                    selectedItem={selectedCMOption?.label}
                    setSelectedItems={(i) => {
                      let test = JSON.parse(JSON.stringify(ruleMappingData));
                      test.channelId = i.value;
                      setRuleMappingData(test);
                      setSelectedCMOption(i);
                    }}
                    options={channelManagerOption}
                  />
                )}
              </div>

              <div
                className=" cursor-pointer text-sm px-4 py-1 bg-blue-500 rounded-md"
                onClick={handleSave}
              >
                Save
              </div>
              {/* <TableButton
              title={"Add New"}
              onClick={handleAddRatePlanClick}
            /> */}
            </div>
          </div>
          {isRoomPlanSelected ? (
            <CMOccupancyMapping
              setIsRoomPlanSelected={setIsRoomPlanSelected}
              isRoomPlanSelected={isRoomPlanSelected}
              setSelectedOccupancyPlan={setSelectedOccupancyPlan}
              selectedOccupancyPlan={selectedOccupancyPlan}
              roomOptions={roomOptions}
              selectedRoomOption={selectedRoomOption}
              setSelectedRoomOption={setSelectedRoomOption}
              selectedRoomPrice={selectedRoomPrice}
              setSelectedRoomPrice={setSelectedRoomPrice}
              ruleMappingData={ruleMappingData}
              setSelectedOccupancyPlanId={setSelectedOccupancyPlanId}
              selectedOccupancyPlanId={selectedOccupancyPlanId}
              selectedPlanRoomId={selectedPlanRoomId}
              setRuleMappingData={setRuleMappingData}
              occupancyData={getCM_RoomDetails?.occupancyDetail}
              applyRule={applyRule}
            />
          ) : isRoomSelected ? (
            <CMRoomPlanMapping
              setIsRoomSelected={setIsRoomSelected}
              isRoomSelected={isRoomSelected}
              setSelectedPlanRoom={setSelectedPlanRoom}
              selectedPlanRoom={selectedPlanRoom}
              roomOptions={roomOptions}
              selectedRoomOption={selectedRoomOption}
              setSelectedRoomOption={setSelectedRoomOption}
              selectedRoomPrice={selectedRoomPrice}
              setSelectedRoomPrice={setSelectedRoomPrice}
              handleAddData={handleAddRoomPlanData}
              ruleMappingData={ruleMappingData}
              setRuleMappingData={setRuleMappingData}
              getCM_RoomDetails={getCM_RoomDetails}
              selectedCMOption={selectedCMOption}
              roomNameMap={roomNameMap}
              roomIdMap={roomIdMap}
              selectedPlanRoomId={selectedPlanRoomId}
              setSelectedOccupancyPlanId={setSelectedOccupancyPlanId}
              setSelectedOccupancyPlan={setSelectedOccupancyPlan}
              setIsRoomPlanSelected={setIsRoomPlanSelected}
              applyRule={applyRule}
              setRoomsClone={setRoomsClone}
            />
          ) : (
            <CMRoomMappin
              roomOptions={roomOptions}
              selectedRoomOption={selectedRoomOption}
              setSelectedRoomOption={setSelectedRoomOption}
              selectedRoomPrice={selectedRoomPrice}
              setSelectedRoomPrice={setSelectedRoomPrice}
              handleAddData={handleAddRoomData}
              roomMapColumns={roomMapColumns}
              ruleMappingData={ruleMappingData}
              applyRule={applyRule}
              setIsRoomSelected={setIsRoomSelected}
              setSelectedPlanRoom={setSelectedPlanRoom}
              setSelectedPlanRoomId={setSelectedPlanRoomId}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CM_RuleMapping;
