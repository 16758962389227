import React, { useState } from "react";
import ChannelConfiguration from "./channels/ChannelConfiguration";
import RoomType from "./roomtype/RoomTypes";
import RatePlan from "./rateplan/RatePlan";
import MealPlan from "./mealplan/MealPlan";
import ChannelCard from "../../settings/channelcard/index";
import Seasons from "./seasons";
import CM_RuleMapping from "./CM_RuleMapping";
import SourceMapping from "./Source Mapping";

const ChannelManager = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const handleCardClick = (cardTitle) => {
    setSelectedCard(cardTitle);
  };
  const handleBackButtonClick = () => {
    setSelectedCard(null);
  };

  const renderComponent = () => {
    switch (selectedCard) {
      case "Channels":
        return (
          <ChannelConfiguration handleBackButtonClick={handleBackButtonClick} />
        );
      case "Rate Plans":
        return <RatePlan handleBackButtonClick={handleBackButtonClick} />;
      case "Room Types":
        return <RoomType handleBackButtonClick={handleBackButtonClick} />;
      case "Meals Plan":
        return <MealPlan handleBackButtonClick={handleBackButtonClick} />;
      // case "Seasons" :
      // return <Seasons handleBackButtonClick={handleBackButtonClick} />
      case "Channel Manager Rule Mapping":
        return <CM_RuleMapping handleBackButtonClick={handleBackButtonClick} />;
      case "Source Mapping":
        return <SourceMapping handleBackButtonClick={handleBackButtonClick} />;
      default:
        return null;
    }
  };

  return (
    <>
      {selectedCard ? (
        <>{renderComponent()}</>
      ) : (
        <div className="flex flex-col bg-transparent  rounded-lg gap-4">
          <div className="text-[17px] text-[#ffffff] h-[44px] flex items-center dark:text-black mt-1">
            Channel Manager
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              {/* <ChannelCard
                title={"Channels"}
                onClick={() => handleCardClick("Channels")}
              /> */}
              {/* <ChannelCard
                title={"Rate Plans"}
                onClick={() => handleCardClick("Rate Plans")}
              />
              <ChannelCard
                title={"Room Types"}
                onClick={() => handleCardClick("Room Types")}
              />
              <ChannelCard
                title={"Meals Plan"}
                onClick={() => handleCardClick("Meals Plan")}
              /> */}
              {/* <ChannelCard
                title={"Seasons"}
                onClick={() => handleCardClick("Seasons")}
              /> */}
              <ChannelCard
                title={"Channel Manager Rule Mapping"}
                onClick={() => handleCardClick("Channel Manager Rule Mapping")}
              />
              {/* <ChannelCard
                title={"Source Mapping"}
                onClick={() => handleCardClick("Source Mapping")}
              /> */}
            </div>
          </div>
          {/* {renderComponent()} */}
        </div>
      )}
    </>
  );
};
export default ChannelManager;
