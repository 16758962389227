import ExcelJS from "exceljs";
import { logoUrl } from "./retvensLogo.js";
import { formatDate } from "./formatDate.js";
import { convertDateToStringDate } from "../../../components/ExcelDownload/RateSuggestion/RateSuggestionExcel.js";

export function getFourMonthRanges() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    // Function to format a date as "1st Nov 23"
    const formatDate = (date) => {
        const options = { day: 'numeric', month: 'short', year: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    // Calculate start and end dates for each range
    const thisMonthStart = new Date(currentYear, currentMonth, 1);
    const thisMonthEnd = new Date(currentYear, currentMonth + 1, 0);

    const lastMonthStart = new Date(currentYear, currentMonth - 1, 1);
    const lastMonthEnd = new Date(currentYear, currentMonth, 0);

    const nextMonthStart = new Date(currentYear, currentMonth + 1, 1);
    const nextMonthEnd = new Date(currentYear, currentMonth + 2, 0);

    const lastYearSameMonthStart = new Date(currentYear - 1, currentMonth, 1);
    const lastYearSameMonthEnd = new Date(currentYear - 1, currentMonth + 1, 0);

    // Format the dates and return the ranges
    return [
        `${formatDate(thisMonthStart)} - ${formatDate(thisMonthEnd)}`,
        `${formatDate(lastMonthStart)} - ${formatDate(lastMonthEnd)}`,
        `${formatDate(nextMonthStart)} - ${formatDate(nextMonthEnd)}`,
        `${formatDate(lastYearSameMonthStart)} - ${formatDate(lastYearSameMonthEnd)}`
    ];
}

export const DownloadRevenueDeckReportExcel = async (temp, hotelName, dayStart) => {
    try {
        let { pickupTrend, paceTrend, otaProductivity, reputation, ranks } = temp;

        let fourMonthRange = getFourMonthRanges()

        function setBorderForRange(worksheet, startCell, endCell, borderStyle) {
            const startRow = startCell.row;
            const startCol = startCell.column;
            const endRow = endCell.row;
            const endCol = endCell.column;

            for (let row = startRow; row <= endRow; row++) {
                for (let col = startCol; col <= endCol; col++) {
                    const cell = worksheet.getCell(row, col);
                    cell.border = borderStyle;
                }
            }
        }

        const workbook = new ExcelJS.Workbook();
        const overviewWorksheet = workbook.addWorksheet("Title");
        const lastMeetingActionWorksheet = workbook.addWorksheet(
            "Last Meeting Action Status"
        );
        const pickupWorksheet = workbook.addWorksheet("Pickup Trend");
        const paceWorksheet = workbook.addWorksheet("Pace Trend");
        const otaProductivityWorksheet = workbook.addWorksheet("OTA Productivity");
        const forecastWorksheet = workbook.addWorksheet("Forecast Week Wise");
        const rankingReputationWorksheet = workbook.addWorksheet(
            "Ranking & Reputation"
        );
        const promotionWorksheet = workbook.addWorksheet("Promotions Data");
        const otaInsightSummaryWorksheet = workbook.addWorksheet("OTA Insights");
        const meetingMinutesWorksheet = workbook.addWorksheet("Meeting Minutes");
        const actionPlanWorksheet = workbook.addWorksheet("Action Plan");

        /* -------------------------------------------------------------------------- */
        /*                                 Sheet Setup                                */
        /* -------------------------------------------------------------------------- */
        const logoBase64 = logoUrl; // Add image at B1

        const columns = [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
        ];
        const columnWidths = [
            15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
            15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
        ];
        const logoId = workbook.addImage({
            base64: logoBase64,
            extension: "jpg",
        });

        /* -------------------------------------------------------------------------- */
        /*                             Overview Worksheet                             */
        /* -------------------------------------------------------------------------- */

        overviewWorksheet.mergeCells("A1:H3");
        overviewWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            overviewWorksheet.getColumn(column).width = columnWidths[index];
        });

        const overviewDateCell = overviewWorksheet.getCell("H1");
        overviewDateCell.value = new Date();
        overviewDateCell.numFmt = "dd mmmm yyyy";

        const mainHeading = overviewWorksheet.getCell("A7");
        mainHeading.value = `Revenue Deck X Hotel ${hotelName} \n\n Date: ${convertDateToStringDate(
            dayStart
        )} \n Time: 13:30 hrs`;
        mainHeading.alignment = { horizontal: "center", vertical: "middle" };
        mainHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };
        mainHeading.font = {
            family: 3,
            size: 20,
            bold: true,
        };

        overviewWorksheet.getRow(7).height = 140; // Increase row height
        overviewWorksheet.mergeCells("A7", "H7");

        /* -------------------------------------------------------------------------- */
        /*                         Last Meeting Action Status                         */
        /* -------------------------------------------------------------------------- */

        lastMeetingActionWorksheet.mergeCells("A1:M3");
        lastMeetingActionWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            lastMeetingActionWorksheet.getColumn(column).width = columnWidths[index];
        });

        const lastMeetingActionDateCell = lastMeetingActionWorksheet.getCell("M1");
        lastMeetingActionDateCell.value = new Date();
        lastMeetingActionDateCell.numFmt = "dd mmmm yyyy";

        const lastMeetingHeading = lastMeetingActionWorksheet.getCell("A4");
        lastMeetingHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        lastMeetingHeading.alignment = { horizontal: "center", vertical: "middle" };
        lastMeetingHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" },
        };

        lastMeetingActionWorksheet.mergeCells("A4", "M4");
        lastMeetingActionWorksheet.getRow(4).height = 40;
        lastMeetingActionWorksheet.mergeCells("A5", "M5");

        const revenueMeetingHeading = lastMeetingActionWorksheet.getCell("A6");
        revenueMeetingHeading.value = `Revenue Meeting: ${hotelName} `;
        revenueMeetingHeading.alignment = { vertical: "middle" };
        lastMeetingActionWorksheet.mergeCells("A6", "M6");

        const meetingMinutesHeaderHeading = lastMeetingActionWorksheet.getCell("A7");
        meetingMinutesHeaderHeading.value = `MEETING MINUTES `;
        meetingMinutesHeaderHeading.alignment = {
            horizontal: "center",
            vertical: "middle",
        };
        meetingMinutesHeaderHeading.font = {
            family: 3,
            bold: true,
        };

        lastMeetingActionWorksheet.mergeCells("A7", "M7");

        const emptySlot = lastMeetingActionWorksheet.getCell("A8");
        emptySlot.value = ` `;
        emptySlot.alignment = { vertical: "middle" };
        lastMeetingActionWorksheet.mergeCells("A8", "M8");

        const attendeByRow = lastMeetingActionWorksheet.getCell("A9");
        attendeByRow.value = `Attended By:  `;
        attendeByRow.alignment = { vertical: "middle" };

        lastMeetingActionWorksheet.mergeCells("A9", "M9");


        const tobeActionedBy = lastMeetingActionWorksheet.getCell("L10");
        tobeActionedBy.value = `To be actioned by `;
        tobeActionedBy.alignment = { vertical: "middle" };

        lastMeetingActionWorksheet.mergeCells("A10", "K10");
        lastMeetingActionWorksheet.mergeCells("L10", "M10");

        const revenueTeamHeading = lastMeetingActionWorksheet.getCell("A11");
        revenueTeamHeading.value = `Revenue Team `;
        revenueTeamHeading.alignment = { vertical: "middle" };
        revenueTeamHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "a9a9a9" }, // Yellow color
        };

        lastMeetingActionWorksheet.mergeCells("A11", "M11");

        for (let i = 1; i < 9; i++) {
            lastMeetingActionWorksheet.mergeCells(`A${11 + i}`, `K${11 + i}`);
            lastMeetingActionWorksheet.mergeCells(`L${11 + i}`, `M${11 + i}`);
        }

        const hotelTeamHeading = lastMeetingActionWorksheet.getCell("A20");
        hotelTeamHeading.value = `Hotel Team `;
        hotelTeamHeading.alignment = { vertical: "middle" };
        hotelTeamHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "a9a9a9" }, // Yellow color
        };

        lastMeetingActionWorksheet.mergeCells("A20", "M20");

        for (let i = 1; i < 5; i++) {
            lastMeetingActionWorksheet.mergeCells(`A${20 + i}`, `K${20 + i}`);
            lastMeetingActionWorksheet.mergeCells(`L${20 + i}`, `M${20 + i}`);
        }
        setBorderForRange(
            lastMeetingActionWorksheet,
            { row: 9, column: 1 },
            { row: 24, column: 13 },
            {
                top: { style: "thin", color: { argb: "000000" } }, // Black color
                left: { style: "thin", color: { argb: "000000" } }, // Black color
                bottom: { style: "thin", color: { argb: "000000" } }, // Black color
                right: { style: "thin", color: { argb: "000000" } }, // Black color
            }
        );

        const meetingClosedHeading = lastMeetingActionWorksheet.getCell("A26");
        meetingClosedHeading.value = `Meeting Closed: `;
        meetingClosedHeading.alignment = { vertical: "middle" };
        meetingClosedHeading.font = {
            family: 3,
            bold: true,
        };
        lastMeetingActionWorksheet.mergeCells("A26", "C26");

        setBorderForRange(
            lastMeetingActionWorksheet,
            { row: 26, column: 1 },
            { row: 26, column: 3 },
            {
                top: { style: "thin", color: { argb: "000000" } }, // Black color
                left: { style: "thin", color: { argb: "000000" } }, // Black color
                bottom: { style: "thin", color: { argb: "000000" } }, // Black color
                right: { style: "thin", color: { argb: "000000" } }, // Black color
            }
        );

        const nextMeetingHeading = lastMeetingActionWorksheet.getCell("A27");
        nextMeetingHeading.value = `Next Meeting: `;
        nextMeetingHeading.alignment = { vertical: "middle" };
        nextMeetingHeading.font = {
            family: 3,
            bold: true,
        };
        lastMeetingActionWorksheet.mergeCells("A27", "C27");
        setBorderForRange(
            lastMeetingActionWorksheet,
            { row: 27, column: 1 },
            { row: 27, column: 3 },
            {
                top: { style: "thin", color: { argb: "000000" } }, // Black color
                left: { style: "thin", color: { argb: "000000" } }, // Black color
                bottom: { style: "thin", color: { argb: "000000" } }, // Black color
                right: { style: "thin", color: { argb: "000000" } }, // Black color
            }
        );

        /* -------------------------------------------------------------------------- */
        /*                              Pickup Worksheet                              */
        /* -------------------------------------------------------------------------- */

        pickupWorksheet.mergeCells("A1:Y3");
        pickupWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            pickupWorksheet.getColumn(column).width = columnWidths[index];
        });

        const PickupDateCell = pickupWorksheet.getCell("Y1");
        PickupDateCell.value = new Date();
        PickupDateCell.numFmt = "dd mmmm yyyy";

        const pickupHeading = pickupWorksheet.getCell("A4");
        pickupHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        pickupHeading.alignment = { horizontal: "center", vertical: "middle" };
        pickupHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };

        pickupWorksheet.mergeCells("A4", "Y4");
        pickupWorksheet.getRow(4).height = 40; // Increase row height
        pickupWorksheet.mergeCells("A5", "Y5");

        let { thisMonth, lastMonth, nextMonth, sameMonthLastYear } = pickupTrend;

        let pickupWorksheetMainMainFirstHeader = [`This Month (${fourMonthRange[0]})`];
        thisMonth?.headerData.map((data) => {
            pickupWorksheetMainMainFirstHeader.push("");
        });
        pickupWorksheetMainMainFirstHeader.push("");
        pickupWorksheetMainMainFirstHeader.push(`Same Month Last Year (${fourMonthRange[3]})`);
        pickupWorksheet.addRow(pickupWorksheetMainMainFirstHeader).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 16,
                bold: true,
            };
        });

        let mainPickupFirstHeaderData = [
            ...thisMonth?.headerData,
            "",
            "",
            ...sameMonthLastYear?.headerData,
        ];

        pickupWorksheet.mergeCells(`A6:${columns[lastMonth?.headerData.length]}6`);
        pickupWorksheet.mergeCells(`${columns[lastMonth?.headerData.length + 2]}6:${columns[lastMonth?.headerData.length + sameMonthLastYear?.headerData.length + 3]}6`);

        pickupWorksheet.addRow(mainPickupFirstHeaderData).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        thisMonth?.bodyData.map((DATA3, id) => {
            let finalOverviewMomBodyData = sameMonthLastYear?.bodyData?.[id]
                ? [...DATA3, "", "", ...sameMonthLastYear?.bodyData?.[id]]
                : [...DATA3];
            pickupWorksheet.addRow(finalOverviewMomBodyData).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });

        pickupWorksheet.addRow([""])

        let pickupWorksheetMainMainSecondHeader = [`Last Month (${fourMonthRange[1]})`];
        thisMonth?.headerData.map((data) => {
            pickupWorksheetMainMainSecondHeader.push("");
        });
        pickupWorksheetMainMainSecondHeader.push("");
        pickupWorksheetMainMainSecondHeader.push(`Next Month (${fourMonthRange[2]})`);
        pickupWorksheet.addRow(pickupWorksheetMainMainSecondHeader).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 16,
                bold: true,
            };
        });;

        let mainPickupSecondHeaderData = [
            ...lastMonth?.headerData,
            "",
            "",
            ...nextMonth?.headerData,
        ];
        pickupWorksheet.addRow(mainPickupSecondHeaderData).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });

        pickupWorksheet.mergeCells(`A24:${columns[lastMonth?.headerData.length]}24`);
        pickupWorksheet.mergeCells(`${columns[lastMonth?.headerData.length + 2]}24:${columns[lastMonth?.headerData.length + nextMonth?.headerData.length + 3]}24`);
        lastMonth?.bodyData.map((DATA3, id) => {
            let finalOverviewMomBodyData = nextMonth?.bodyData?.[id]
                ? [...DATA3, "", "", ...nextMonth?.bodyData?.[id]]
                : [...DATA3];
            pickupWorksheet.addRow(finalOverviewMomBodyData).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });


        /* -------------------------------------------------------------------------- */
        /*                               Pace worksheet                               */
        /* -------------------------------------------------------------------------- */

        paceWorksheet.mergeCells("A1:Y3");
        paceWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            paceWorksheet.getColumn(column).width = columnWidths[index];
        });

        const PaceDateCell = paceWorksheet.getCell("H1");
        PaceDateCell.value = new Date();
        PaceDateCell.numFmt = "dd mmmm yyyy";

        const paceHeading = paceWorksheet.getCell("A4");
        paceHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        paceHeading.alignment = { horizontal: "center", vertical: "middle" };
        paceHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" },
        };

        paceWorksheet.mergeCells("A4", "H4");
        paceWorksheet.getRow(4).height = 40; // Increase row height
        paceWorksheet.mergeCells("A5", "H5");

        let { weeklyArrival, weeklyReservation, weeklyRoomNights } = paceTrend;


        paceWorksheet.addRow([`Weekly Arrivals (${fourMonthRange[0]})`]).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 16,
                bold: true,
            };
        });
        let lastRow = Number(paceWorksheet.lastRow.number)
        paceWorksheet.mergeCells(`${columns[0]}${lastRow}:${columns[6]}${lastRow}`);
        paceWorksheet.addRow(weeklyArrival?.headerData).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        weeklyArrival?.bodyData.map((DATA3, id) => {
            paceWorksheet.addRow(DATA3).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });
        paceWorksheet.addRow([""]);

        paceWorksheet.addRow([`Weekly Reservations (${fourMonthRange[0]})`]).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 16,
                bold: true,
            };
        });
        lastRow = Number(paceWorksheet.lastRow.number)
        paceWorksheet.mergeCells(`${columns[0]}${lastRow}:${columns[6]}${lastRow}`);
        paceWorksheet.addRow(weeklyReservation?.headerData).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        lastRow = Number(paceWorksheet.lastRow.number)
        paceWorksheet.mergeCells(`B${lastRow}`, `C${lastRow}`);
        paceWorksheet.mergeCells(`D${lastRow}`, `E${lastRow}`);
        paceWorksheet.mergeCells(`F${lastRow}`, `G${lastRow}`);
        paceWorksheet.addRow(weeklyReservation?.subHeaderData).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });

        lastRow = Number(paceWorksheet.lastRow.number)
        paceWorksheet.mergeCells(`A${lastRow - 1}`, `A${lastRow}`);
        weeklyReservation?.bodyData.map((DATA3, id) => {
            paceWorksheet.addRow(DATA3).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });
        paceWorksheet.addRow([""]);

        paceWorksheet.addRow([`Weekly Room Nights (${fourMonthRange[0]})`]).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 16,
                bold: true,
            };
        });
        lastRow = Number(paceWorksheet.lastRow.number)
        paceWorksheet.mergeCells(`${columns[0]}${lastRow}:${columns[6]}${lastRow}`);
        paceWorksheet.addRow(weeklyRoomNights?.headerData).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        lastRow = Number(paceWorksheet.lastRow.number)
        paceWorksheet.mergeCells(`B${lastRow}`, `C${lastRow}`);
        paceWorksheet.mergeCells(`D${lastRow}`, `E${lastRow}`);
        paceWorksheet.mergeCells(`F${lastRow}`, `G${lastRow}`);
        paceWorksheet.addRow(weeklyRoomNights?.subHeaderData).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });

        lastRow = Number(paceWorksheet.lastRow.number)
        paceWorksheet.mergeCells(`A${lastRow - 1}`, `A${lastRow}`);
        weeklyRoomNights?.bodyData.map((DATA3, id) => {
            paceWorksheet.addRow(DATA3).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });

        // weekly reservation & room nights

        /* -------------------------------------------------------------------------- */
        /*                          otaProductivityWorksheet                          */
        /* -------------------------------------------------------------------------- */

        otaProductivityWorksheet.mergeCells("A1:AD3");
        otaProductivityWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            otaProductivityWorksheet.getColumn(column).width = columnWidths[index];
        });

        const otaProductivityWorksheetDateCell =
            otaProductivityWorksheet.getCell("AD1");
        otaProductivityWorksheetDateCell.value = new Date();
        otaProductivityWorksheetDateCell.numFmt = "dd mmmm yyyy";

        const otaProductivityHeading = otaProductivityWorksheet.getCell("A4");
        otaProductivityHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        otaProductivityHeading.alignment = {
            horizontal: "center",
            vertical: "middle",
        };
        otaProductivityHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };

        otaProductivityWorksheet.mergeCells("A4", "AD4");
        otaProductivityWorksheet.getRow(4).height = 40; // Increase row height
        otaProductivityWorksheet.mergeCells("A5", "AD5");

        //monthwise breakdown

        let mainDatanHeading = ["OTA Productivity for This year"];
        otaProductivity.thisYear.revenueBreakdown?.headerData.map((xyz) => {
            mainDatanHeading.push("");
        });
        mainDatanHeading.push("", "OTA Productivity for Last year");

        otaProductivityWorksheet.addRow(mainDatanHeading).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 16,
                bold: true,
            };
        });

        lastRow = Number(otaProductivityWorksheet.lastRow.number)
        otaProductivityWorksheet.mergeCells(`A${lastRow}:M${lastRow}`);
        otaProductivityWorksheet.mergeCells(`P${lastRow}:AB${lastRow}`);
        otaProductivityWorksheet.addRow([""])


        mainDatanHeading[0] = "Room Nights"
        mainDatanHeading[15] = "Room Nights"
        otaProductivityWorksheet.addRow(mainDatanHeading).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 12,
                bold: true,
            };
        });
        lastRow = Number(otaProductivityWorksheet.lastRow.number)
        otaProductivityWorksheet.mergeCells(`A${lastRow}:M${lastRow}`);
        otaProductivityWorksheet.mergeCells(`P${lastRow}:AB${lastRow}`);
        let mainMonthwiseHeader = [
            ...otaProductivity.thisYear.rnsBreakdown.headerData,
            "",
            "",
            ...otaProductivity.lastYear.rnsBreakdown.headerData,
        ];
        otaProductivityWorksheet.addRow(mainMonthwiseHeader).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        otaProductivity.thisYear.rnsBreakdown?.bodyData.map((DATA3, id) => {
            let finalMonthwiseBodyData = otaProductivity.lastYear.rnsBreakdown?.bodyData[id]
                ? [...DATA3, "", "", ...otaProductivity.lastYear.rnsBreakdown.bodyData[id]]
                : [...DATA3];
            otaProductivityWorksheet.addRow(finalMonthwiseBodyData).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });
        otaProductivityWorksheet.addRow([]);

        mainDatanHeading[0] = "Revenue"
        mainDatanHeading[15] = "Revenue"
        otaProductivityWorksheet.addRow(mainDatanHeading).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 12,
                bold: true,
            };
        });
        lastRow = Number(otaProductivityWorksheet.lastRow.number)
        otaProductivityWorksheet.mergeCells(`A${lastRow}:M${lastRow}`);
        otaProductivityWorksheet.mergeCells(`P${lastRow}:AB${lastRow}`);
        mainMonthwiseHeader = [
            ...otaProductivity.thisYear.revenueBreakdown.headerData,
            "",
            "",
            ...otaProductivity.lastYear.revenueBreakdown.headerData,
        ];
        otaProductivityWorksheet.addRow(mainMonthwiseHeader).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        otaProductivity.thisYear.revenueBreakdown?.bodyData.map((DATA3, id) => {
            let finalMonthwiseBodyData = otaProductivity.lastYear.revenueBreakdown?.bodyData[id]
                ? [...DATA3, "", "", ...otaProductivity.lastYear.revenueBreakdown.bodyData[id]]
                : [...DATA3];
            otaProductivityWorksheet.addRow(finalMonthwiseBodyData).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });
        otaProductivityWorksheet.addRow([]);

        mainDatanHeading[0] = "Average Daily Rate"
        mainDatanHeading[15] = "Average Daily Rate"
        otaProductivityWorksheet.addRow(mainDatanHeading).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 12,
                bold: true,
            };
        });
        lastRow = Number(otaProductivityWorksheet.lastRow.number)
        otaProductivityWorksheet.mergeCells(`A${lastRow}:M${lastRow}`);
        otaProductivityWorksheet.mergeCells(`P${lastRow}:AB${lastRow}`);
        mainMonthwiseHeader = [
            ...otaProductivity.thisYear.adrBreakdown.headerData,
            "",
            "",
            ...otaProductivity.lastYear.adrBreakdown.headerData,
        ];
        otaProductivityWorksheet.addRow(mainMonthwiseHeader).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        otaProductivity.thisYear.adrBreakdown?.bodyData.map((DATA3, id) => {
            let finalMonthwiseBodyData = otaProductivity.lastYear.adrBreakdown?.bodyData[id]
                ? [...DATA3, "", "", ...otaProductivity.lastYear.adrBreakdown.bodyData[id]]
                : [...DATA3];
            otaProductivityWorksheet.addRow(finalMonthwiseBodyData).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });
        otaProductivityWorksheet.addRow([]);

        mainDatanHeading[0] = "Average Length Of Stay"
        mainDatanHeading[15] = "Average Length Of Stay"
        otaProductivityWorksheet.addRow(mainDatanHeading).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 12,
                bold: true,
            };
        });
        lastRow = Number(otaProductivityWorksheet.lastRow.number)
        otaProductivityWorksheet.mergeCells(`A${lastRow}:M${lastRow}`);
        otaProductivityWorksheet.mergeCells(`P${lastRow}:AB${lastRow}`);
        mainMonthwiseHeader = [
            ...otaProductivity.thisYear.losBreakdown.headerData,
            "",
            "",
            ...otaProductivity.lastYear.losBreakdown.headerData,
        ];
        otaProductivityWorksheet.addRow(mainMonthwiseHeader).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        otaProductivity.thisYear.losBreakdown?.bodyData.map((DATA3, id) => {
            let finalMonthwiseBodyData = otaProductivity.lastYear.losBreakdown?.bodyData[id]
                ? [...DATA3, "", "", ...otaProductivity.lastYear.losBreakdown.bodyData[id]]
                : [...DATA3];
            otaProductivityWorksheet.addRow(finalMonthwiseBodyData).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });
        otaProductivityWorksheet.addRow([]);

        mainDatanHeading[0] = "Lead Time"
        mainDatanHeading[15] = "Lead Time"
        otaProductivityWorksheet.addRow(mainDatanHeading).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 12,
                bold: true,
            };
        });
        lastRow = Number(otaProductivityWorksheet.lastRow.number)
        otaProductivityWorksheet.mergeCells(`A${lastRow}:M${lastRow}`);
        otaProductivityWorksheet.mergeCells(`P${lastRow}:AB${lastRow}`);
        mainMonthwiseHeader = [
            ...otaProductivity.thisYear.leadTimeBreakdown.headerData,
            "",
            "",
            ...otaProductivity.lastYear.leadTimeBreakdown.headerData,
        ];
        otaProductivityWorksheet.addRow(mainMonthwiseHeader).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        otaProductivity.thisYear.leadTimeBreakdown?.bodyData.map((DATA3, id) => {
            let finalMonthwiseBodyData = otaProductivity.lastYear.leadTimeBreakdown?.bodyData[id]
                ? [...DATA3, "", "", ...otaProductivity.lastYear.leadTimeBreakdown.bodyData[id]]
                : [...DATA3];
            otaProductivityWorksheet.addRow(finalMonthwiseBodyData).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });
        otaProductivityWorksheet.addRow([]);

        /* -------------------------------------------------------------------------- */
        /*                              forecastWorksheet                             */
        /* -------------------------------------------------------------------------- */

        forecastWorksheet.mergeCells("A1:Y3");
        forecastWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            forecastWorksheet.getColumn(column).width = columnWidths[index];
        });

        const forecastWorksheetDateCell = forecastWorksheet.getCell("Y1");
        forecastWorksheetDateCell.value = new Date();
        forecastWorksheetDateCell.numFmt = "dd mmmm yyyy";

        const forecastHeading = forecastWorksheet.getCell("A4");
        forecastHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        forecastHeading.alignment = { horizontal: "center", vertical: "middle" };
        forecastHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };

        forecastWorksheet.mergeCells("A4", "Y4");
        forecastWorksheet.getRow(4).height = 40; // Increase row height
        forecastWorksheet.mergeCells("A5", "Y5");

        /* -------------------------------------------------------------------------- */
        /*                         rankingReputationWorksheet                         */
        /* -------------------------------------------------------------------------- */

        rankingReputationWorksheet.mergeCells("A1:I3");
        rankingReputationWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            rankingReputationWorksheet.getColumn(column).width = columnWidths[index];
        });

        const rankingReputationWorksheetDateCell =
            rankingReputationWorksheet.getCell("I1");
        rankingReputationWorksheetDateCell.value = new Date();
        rankingReputationWorksheetDateCell.numFmt = "dd mmmm yyyy";

        const rankingHeading = rankingReputationWorksheet.getCell("A4");
        rankingHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        rankingHeading.alignment = { horizontal: "center", vertical: "middle" };
        rankingHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };

        rankingReputationWorksheet.mergeCells("A4", "I4");
        rankingReputationWorksheet.getRow(4).height = 40; // Increase row height
        rankingReputationWorksheet.mergeCells("A5", "I5");


        //reputation breakdown

        let reputatopmDatanHeading = ["Today's meet"];
        reputation.thisMonth.headerData.map((xyz) => {
            reputatopmDatanHeading.push("");
        });
        reputatopmDatanHeading.push("", "Last's Meet");

        rankingReputationWorksheet.addRow(reputatopmDatanHeading).eachCell((cell) => {
            cell.font = {
                family: 4,
                size: 16,
                bold: true,
            };
        });;

        let mainRankingReputationHeaderData = [
            ...reputation.thisMonth.headerData,
            "",
            "",
            ...reputation.lastMonth.headerData,
        ];
        rankingReputationWorksheet.addRow(mainRankingReputationHeaderData).eachCell((cell) => {
            if (
                cell?._value?.model?.value != "" &&
                cell?._value?.model?.value != " " &&
                cell?._value?.model?.value != "RemoveSpace"
            ) {
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "B7D166" },
                };
            }
            cell.border = {
                top: { style: "thin", color: { argb: "ffffff" } },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
            };
        });
        reputation.thisMonth?.bodyData.map((DATA3, id) => {
            let finalOverviewMomBodyData = reputation.lastMonth.bodyData?.[id]
                ? [...DATA3, "", "", ...reputation.lastMonth?.bodyData?.[id]]
                : [...DATA3];
            rankingReputationWorksheet.addRow(finalOverviewMomBodyData).eachCell((cell) => {
                if (DATA3.length !== 1) {
                    if (
                        cell?._value?.model?.value != "" &&
                        cell?._value?.model?.value != " " &&
                        cell?._value?.model?.value != "RemoveSpace"
                    ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "ECEFCE" },
                        };
                    }
                }
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        });
        rankingReputationWorksheet.addRow([]);


        /* -------------------------------------------------------------------------- */
        /*                             promotionWorksheet                             */
        /* -------------------------------------------------------------------------- */

        promotionWorksheet.mergeCells("A1:I3");
        promotionWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            promotionWorksheet.getColumn(column).width = columnWidths[index];
        });

        const promotionWorksheetDateCell = promotionWorksheet.getCell("I1");
        promotionWorksheetDateCell.value = new Date();
        promotionWorksheetDateCell.numFmt = "dd mmmm yyyy";

        const promotionHeading = promotionWorksheet.getCell("A4");
        promotionHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        promotionHeading.alignment = { horizontal: "center", vertical: "middle" };
        promotionHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };

        promotionWorksheet.mergeCells("A4", "I4");
        promotionWorksheet.getRow(4).height = 40; // Increase row height
        promotionWorksheet.mergeCells("A5", "I5");

        const promotionMainHeading = promotionWorksheet.getCell("A7");
        promotionMainHeading.value = `Promotions & Packages `;
        promotionMainHeading.alignment = { horizontal: "center", vertical: "middle" };
        promotionMainHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };

        promotionWorksheet.mergeCells("A7", "H7");
        promotionWorksheet.getRow(7).height = 40; // Increase row height

        promotionWorksheet.addRow(["Product code and name", "Rate Level code", "Stay Validity", "", "Amount", "Currency", "RN", "Offers"])
        promotionWorksheet.addRow(["", "", "From \ndd/mmm/yy", "To \ndd/mmm/yy \nincluded", "", "", ""])
        promotionWorksheet.mergeCells("A8", "A9");
        promotionWorksheet.mergeCells("B8", "B9");
        promotionWorksheet.mergeCells("E8", "E9");
        promotionWorksheet.mergeCells("F8", "F9");
        promotionWorksheet.mergeCells("G8", "G9");
        promotionWorksheet.mergeCells("H8", "H9");
        promotionWorksheet.mergeCells("C8", "D8");

        promotionWorksheet.getColumn(1).width = 20
        promotionWorksheet.getColumn(2).width = 20
        promotionWorksheet.getColumn(8).width = 40

        setBorderForRange(
            promotionWorksheet,
            { row: 8, column: 1 },
            { row: 13, column: 8 },
            {
                top: { style: "thin", color: { argb: "000000" } }, // Black color
                left: { style: "thin", color: { argb: "000000" } }, // Black color
                bottom: { style: "thin", color: { argb: "000000" } }, // Black color
                right: { style: "thin", color: { argb: "000000" } }, // Black color
            }
        );


        /* -------------------------------------------------------------------------- */
        /*                              otaInisghtWorksheet                             */
        /* -------------------------------------------------------------------------- */

        otaInsightSummaryWorksheet.mergeCells("A1:Y3");
        otaInsightSummaryWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            otaInsightSummaryWorksheet.getColumn(column).width = columnWidths[index];
        });

        const otaInsightSummaryWorksheetDateCell = otaInsightSummaryWorksheet.getCell("Y1");
        otaInsightSummaryWorksheetDateCell.value = new Date();
        otaInsightSummaryWorksheetDateCell.numFmt = "dd mmmm yyyy";

        const otaInsightSummaryWorksheetHeading = otaInsightSummaryWorksheet.getCell("A4");
        otaInsightSummaryWorksheetHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        otaInsightSummaryWorksheetHeading.alignment = { horizontal: "center", vertical: "middle" };
        otaInsightSummaryWorksheetHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };

        otaInsightSummaryWorksheet.mergeCells("A4", "Y4");
        otaInsightSummaryWorksheet.getRow(4).height = 40; // Increase row height
        otaInsightSummaryWorksheet.mergeCells("A5", "Y5");

        /* -------------------------------------------------------------------------- */
        /*                           meetingMinutesWorksheet                          */
        /* -------------------------------------------------------------------------- */

        meetingMinutesWorksheet.mergeCells("A1:N3");
        meetingMinutesWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            meetingMinutesWorksheet.getColumn(column).width = columnWidths[index];
        });

        const meetingMinutesWorksheetDateCell = meetingMinutesWorksheet.getCell("N1");
        meetingMinutesWorksheetDateCell.value = new Date();
        meetingMinutesWorksheetDateCell.numFmt = "dd mmmm yyyy";

        const meetingMinutesHeading = meetingMinutesWorksheet.getCell("A4");
        meetingMinutesHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        meetingMinutesHeading.alignment = {
            horizontal: "center",
            vertical: "middle",
        };
        meetingMinutesHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };

        meetingMinutesWorksheet.mergeCells("A4", "N4");
        meetingMinutesWorksheet.getRow(4).height = 40; // Increase row height
        meetingMinutesWorksheet.mergeCells("A5", "N5");


        const meetingMinutesMainHeading = meetingMinutesWorksheet.getCell("A6");
        meetingMinutesMainHeading.value = `Revenue Meeting: ${hotelName} `;
        meetingMinutesMainHeading.alignment = { vertical: "middle" };
        meetingMinutesWorksheet.mergeCells("A6", "M6");

        const meetingMinutesMainHeaderHeading = meetingMinutesWorksheet.getCell("A7");
        meetingMinutesHeaderHeading.value = `MEETING MINUTES `;
        meetingMinutesHeaderHeading.alignment = {
            horizontal: "center",
            vertical: "middle",
        };
        meetingMinutesHeaderHeading.font = {
            family: 4,
            size: 16,
            bold: true,
        };

        meetingMinutesWorksheet.mergeCells("A7", "M7");

        const meetingMinutesAttendeByRow = meetingMinutesWorksheet.getCell("A8");
        meetingMinutesAttendeByRow.value = `Attended By:  `;
        meetingMinutesAttendeByRow.alignment = { vertical: "middle" };

        meetingMinutesWorksheet.mergeCells("A8", "M8");

        const meetingMinutesTobeActionedBy = meetingMinutesWorksheet.getCell("L9");
        meetingMinutesTobeActionedBy.value = `To be actioned by `;
        meetingMinutesTobeActionedBy.alignment = { vertical: "middle" };

        meetingMinutesWorksheet.mergeCells("A9", "K9");
        meetingMinutesWorksheet.mergeCells("L9", "M9");

        const meetingMinutesTeamHeading = meetingMinutesWorksheet.getCell("A10");
        meetingMinutesTeamHeading.value = `Revenue Team `;
        meetingMinutesTeamHeading.alignment = { vertical: "middle" };
        meetingMinutesTeamHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "a9a9a9" }, // Yellow color
        };

        meetingMinutesWorksheet.mergeCells("A10", "M10");

        for (let i = 1; i < 10; i++) {
            meetingMinutesWorksheet.mergeCells(`A${10 + i}`, `K${10 + i}`);
            meetingMinutesWorksheet.mergeCells(`L${10 + i}`, `M${10 + i}`);
        }

        const meetingMinutesHotelTeamHeading = meetingMinutesWorksheet.getCell("A20");
        meetingMinutesHotelTeamHeading.value = `Hotel Team `;
        meetingMinutesHotelTeamHeading.alignment = { vertical: "middle" };
        meetingMinutesHotelTeamHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "a9a9a9" }, // Yellow color
        };

        meetingMinutesWorksheet.mergeCells("A20", "M20");

        for (let i = 1; i < 5; i++) {
            meetingMinutesWorksheet.mergeCells(`A${20 + i}`, `K${20 + i}`);
            meetingMinutesWorksheet.mergeCells(`L${20 + i}`, `M${20 + i}`);
        }
        setBorderForRange(
            meetingMinutesWorksheet,
            { row: 9, column: 1 },
            { row: 24, column: 13 },
            {
                top: { style: "thin", color: { argb: "000000" } }, // Black color
                left: { style: "thin", color: { argb: "000000" } }, // Black color
                bottom: { style: "thin", color: { argb: "000000" } }, // Black color
                right: { style: "thin", color: { argb: "000000" } }, // Black color
            }
        );

        const meetingMinutesClosedHeading = meetingMinutesWorksheet.getCell("A26");
        meetingMinutesClosedHeading.value = `Meeting Closed: `;
        meetingMinutesClosedHeading.alignment = { vertical: "middle" };
        meetingMinutesClosedHeading.font = {
            family: 3,
            bold: true,
        };
        meetingMinutesWorksheet.mergeCells("A26", "C26");

        setBorderForRange(
            meetingMinutesWorksheet,
            { row: 26, column: 1 },
            { row: 26, column: 3 },
            {
                top: { style: "thin", color: { argb: "000000" } }, // Black color
                left: { style: "thin", color: { argb: "000000" } }, // Black color
                bottom: { style: "thin", color: { argb: "000000" } }, // Black color
                right: { style: "thin", color: { argb: "000000" } }, // Black color
            }
        );

        const nextMeetingMinutesHeading = meetingMinutesWorksheet.getCell("A27");
        nextMeetingMinutesHeading.value = `Next Meeting: `;
        nextMeetingMinutesHeading.alignment = { vertical: "middle" };
        nextMeetingMinutesHeading.font = {
            family: 3,
            bold: true,
        };
        meetingMinutesWorksheet.mergeCells("A27", "C27");
        setBorderForRange(
            meetingMinutesWorksheet,
            { row: 27, column: 1 },
            { row: 27, column: 3 },
            {
                top: { style: "thin", color: { argb: "000000" } }, // Black color
                left: { style: "thin", color: { argb: "000000" } }, // Black color
                bottom: { style: "thin", color: { argb: "000000" } }, // Black color
                right: { style: "thin", color: { argb: "000000" } }, // Black color
            }
        );

        /* -------------------------------------------------------------------------- */
        /*                             actionPlanWorksheet                            */
        /* -------------------------------------------------------------------------- */

        actionPlanWorksheet.mergeCells("A1:F3");
        actionPlanWorksheet.addImage(logoId, "A1:A3");
        columns.forEach((column, index) => {
            actionPlanWorksheet.getColumn(column).width = columnWidths[index];
        });

        const actionPlanWorksheetDateCell = actionPlanWorksheet.getCell("F1");
        actionPlanWorksheetDateCell.value = new Date();
        actionPlanWorksheetDateCell.numFmt = "dd mmmm yyyy";

        const actionPlanHeading = actionPlanWorksheet.getCell("A4");
        actionPlanHeading.value = `Revenue Deck X Hotel ${hotelName} `;
        actionPlanHeading.alignment = { horizontal: "center", vertical: "middle" };
        actionPlanHeading.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B7D166" }, // Yellow color
        };

        actionPlanWorksheet.mergeCells("A4", "F4");
        actionPlanWorksheet.getRow(4).height = 40; // Increase row height
        actionPlanWorksheet.mergeCells("A5", "F5");


        const actionPlanMainHeading = actionPlanWorksheet.getCell("A7");
        actionPlanMainHeading.value = `Action Plan & Next Steps `;
        actionPlanMainHeading.font = {
            family: 4,
            size: 16,
            bold: true,
        };
        actionPlanWorksheet.addRow(["S.No.", "Action Plan"]);

        actionPlanWorksheet.getColumn(2).width = 40;

        setBorderForRange(
            actionPlanWorksheet,
            { row: 8, column: 1 },
            { row: 17, column: 2 },
            {
                top: { style: "thin", color: { argb: "000000" } }, // Black color
                left: { style: "thin", color: { argb: "000000" } }, // Black color
                bottom: { style: "thin", color: { argb: "000000" } }, // Black color
                right: { style: "thin", color: { argb: "000000" } }, // Black color
            }
        );


        /* -------------------------------------------------------------------------- */
        /*                                configuration                               */
        /* -------------------------------------------------------------------------- */


        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element and trigger a download
        const a = document.createElement("a");
        a.href = url;
        a.download = `Dynamic Rate Change Report ${temp?.hotelName} .xlsx`; // Set the desired filename here
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
    }
    catch (err) {
        alert("No enough data to download")
    }
};
